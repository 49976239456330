import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./colors.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
// import { store } from "./redux_toolkit/Store";
import { Helmet } from "react-helmet";
import store from "../src/redux_toolkit/Store";
import { ToastContainer } from "react-toastify";
import { useGetSettingQuery } from "./redux_toolkit/apiUser";
import "../src/assets/css/main.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import GlobalStyle from "./components/GlobalStyle";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <GlobalStyle>
        <App />
      </GlobalStyle>
    </React.StrictMode>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      closeOnClick
      pauseOnHover
      theme="light"
    />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
