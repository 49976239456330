import "./banner.css";
import { useEffect, useState, useRef } from "react";
import banner1 from "../../../../assets/images/banner1.jpg";
import banner2 from "../../../../assets/images/banner2.jpg";
import axios from "axios";
import { toast } from "react-toastify";
import { headers } from "../../../../utils/const";
import config from "../../../../config";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const domain = config.REACT_APP_DOMAIN;
// const domain = process.env.REACT_APP_DOMAIN;
const delay = 2500;

export function Slideshow() {
  const [banners, setBanner] = useState([]);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const timeoutRef = useRef(null);
  const banner = [banner1, banner2];
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    setLoading(true);
    axios
      .get(`${domain}/api/user/getBanner`, {
        headers,
      })
      .then(function (response) {
        setLoading(false);
        let status = response.data.status;
        if (status === true) {
          setBanner(response.data.data);
        }
        return toast.error(response.data.msg);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Lỗi server");
      });
  }, []);

  return (
    <>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {banners.map((item, i) => (
          <SwiperSlide key={i}>
            <img
              className="h-[250px] w-full bg-blue-gray-400 rounded-md"
              src={domain + item.link}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
    // <div className="slideshow">
    //   <div
    //     className="slideshowSlider"
    //     style={{ transform: `translate3D(${-index * 100}%, 0, 0)` }}
    //   >
    //     {banners.map((item, index) => (
    //       <img
    //         className="slide bg-blue-gray-400 h-[350px!important]"
    //         key={index}
    //         src={domain + item.link}
    //       ></img>
    //     ))}
    //   </div>
    // </div>
  );
}
