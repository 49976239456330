import { useState } from "react";
import Countdown2 from "../../../../components/cowndown/cowndown2";
import Dialog__Howplay_xx from "../../../../components/modal/Dialog_cachchoi_xucxac";
import Xucxac_spin from "../../../../components/spin/xucxac_spin/xucxac_spin";
import { convertPhien } from "../../../../utils/function";

function Heading_xucxac({ message, time, phien }) {
  // console.log(message);
  const [dialogHowPlayXX, setDialogHowPlayXX] = useState(false);
  return (
    <div className="rounded-3xl z-10 bg-white sticky top-0 shadow ">
      <div className="flex pt-12 pb-6 relative">
        <div className="flex py-4 flex-col items-center justify-center w-[50%]">
          <span className="text-gray-400 text-3xl  md:text-[1.7rem] sm:text-[1.6rem]">
            Phiên số:
          </span>
          <span className="text-4xl md:text-[1.9rem] sm:text-[1.7rem] py-4 font-bold text-bluelight">
            {convertPhien(phien)}
          </span>
          {/* <button
            onClick={() => setDialogHowPlayXX(!dialogHowPlayXX)}
            className="border border-solid border-[red] rounded-full text-[red] p-2 text-[1.3rem] md:text-[1.1rem] sm:text-[1rem] mt-4"
          >
            Hướng dẫn cách chơi
          </button> */}
        </div>
        <div className="flex py-4 flex-col items-center justify-center w-[50%]">
          <span>Thời gian còn lại:</span>
          <Countdown2 time={time} />
        </div>
        <div className="absolute h"></div>
      </div>
      <Xucxac_spin message={message} time={time} />
      <Dialog__Howplay_xx
        dialog={dialogHowPlayXX}
        toggle={() => setDialogHowPlayXX(!dialogHowPlayXX)}
      />
    </div>
  );
}

export default Heading_xucxac;
