import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Slideshow } from "./components/banner/banner2";
import "./home.css";
import { useNavigate } from "react-router-dom";

import { xsn, xs3m, xd, xx, kn, tx, du_an_tinh } from "../../utils/const";
import {
  xo_so_3_mien,
  xo_so_nhanh,
  xoc_dia,
  xuc_sac,
  ke_no,
  tai_xiu,
  khuyen_mai,
} from "../images";
import {
  Menu_item_content_1,
  Menu_item_content_2,
  Menu_item_content_3,
  Banner,
  SliderNotification,
} from "../home/components";
import Loading from "../../components/common/loading";
import { useGetUsersInfoQuery } from "../../redux_toolkit/apiUser";
import Menu_item_content_4 from "./components/menu_item_content_2/menu2";
import { toast } from "react-toastify";

function Home() {
  const { data, isLoading } = useGetUsersInfoQuery();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading) {
      localStorage.setItem("username", data?.username);
      setLoading(false);
    }
  }, [isLoading]);
  // let [menu, setMenu] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-wrapper">
      <Loading loading={loading} />
      <div className="home-slider mb-4">
        {/* <Banner /> */}
        <Slideshow />
      </div>
      <SliderNotification />
      <div className="text-left title-listgame relative pl-4 mt-8 mb-4 font-semibold text-blue-800">
        Danh sách dự án
      </div>
      <div>
        {/* <div className="flex justify-between my-15 lg:h-[18rem] xs:h-[14rem] mx-[-0.5rem]">
          <div className="menu-item-1" onClick={() => setMenu(0)}>
            <img src={xo_so_3_mien} className="menu-img" />
            <span className="menu-text-1">Xổ số 3 miền</span>
          </div>
          <div className="menu-item-1" onClick={() => setMenu(1)}>
            <img src={xo_so_nhanh} className="menu-img" />
            <span className="menu-text-1">Xổ số nhanh</span>
          </div>
          <div className="menu-item-1" onClick={() => setMenu(2)}>
            <img src={xoc_dia} className="menu-img" />
            <span className="menu-text-1">Xóc đĩa</span>
          </div>
        </div>
        <div className="menu-box">
          <div className="menu-item-2" onClick={() => setMenu(3)}>
            <img src={xuc_sac} className="menu-img h-[90%]" />
            <span className="menu-text-2">Xúc sắc</span>
          </div>
          <div className="menu-item-2" onClick={() => setMenu(4)}>
            <img src={ke_no} className="menu-img h-[90%]" />
            <span className="menu-text-2">Keno</span>
          </div>
          <div className="menu-item-2" onClick={() => setMenu(5)}>
            <img src={tai_xiu} className="menu-img h-[90%] padding-img" />
            <span className="menu-text-2">Tài xỉu</span>
          </div>
          <Link to="/promotion" className="menu-item-2">
            <img src={khuyen_mai} className="menu-img h-[90%] padding-img" />
            <span className="menu-text-2">Khuyến mãi</span>
          </Link>
        </div> */}
        <div className="list-items">
          <div className="grid grid-cols-1  p-4 gap-6 ">
            {du_an_tinh.map((item, i) => {
              return (
                <div
                  onClick={() => {
                    if (item.link) {
                      navigate(item.link);
                      return;
                    }
                    toast("Dự án đang phát triển");
                    return;
                  }}
                  key={i}
                  // style={{
                  //   background: "url(/lotto/bg.png)",
                  //   backgroundSize: "100% 100%",
                  // }}
                  // className="w-auto flex items-center justify-between cursor-pointer rounded-md background-game  p-4 sm:text-[13px] md:text-[14px]"
                  className="w-auto flex items-center justify-between cursor-pointer rounded-md p-4 sm:text-[13px] md:text-[14px]"
                  style={{
                    background: "url(/xosovip/bg_game.png)",
                    backgroundSize: "100% 100%",
                  }}
                >
                  <div className="text-left w-[75%] font-bold text-blue-500 text-[24px] md:text-[22px] sm:text-[20px]">
                    {/* <p className="text-[16px] md:text-[14px] sm:text-[12px] font-medium text-shadow2">
                      
                    </p> */}
                    <label className=" text-shadow text-red-500 whitespace-nowrap">
                      Dự án {item.title}
                    </label>
                  </div>
                  <img src={item.img} className="w-[25%] rounded-[10px]" />
                </div>
              );
            })}
          </div>
          {/* <Menu_item_content_2 obj={kn} /> */}
          {/* <Menu_item_content_1 obj={xs3m} /> */}
          {/* <Menu_item_content_2 obj={xsn} /> */}
          {/* <Menu_item_content_4 obj={du_an_tinh} /> */}
          {/* {menu === 2 && Menu_item_content_2(xd)} */}
          {/* {menu === 5 && Menu_item_content_3(tx)} */}
        </div>
      </div>
    </div>
  );
}

export default Home;
