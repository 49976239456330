import "../login_register.css";
import register_img from "../../../assets/images/register_img.png";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { InputGroupStyle } from "../../../components/input/input";
import { BiKey } from "react-icons/bi";
import { FaUser, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { Button } from "@material-tailwind/react";
import { useGetSettingQuery } from "../../../redux_toolkit/apiUser";
import config from "../../../config";

const domain = config.REACT_APP_DOMAIN;
// const domain = process.env.REACT_APP_DOMAIN;

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [typePassword, setTypePassword] = useState(true);

  const { data, isLoading } = useGetSettingQuery();

  const onChangeTypePW = () => {
    setTypePassword(!typePassword);
  };

  const handleClick = () => {
    axios
      .post(`${domain}/api/user/login`, {
        username: username,
        password: password,
      })
      .then(function (response) {
        let status = response.data.status;
        if (status === true) {
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
          localStorage.setItem("auth", response.data.data);
          return toast.success(response.data.msg);
        }
        return toast.error(response.data.msg);
      })
      .catch(function (error) {
        toast.error("Lỗi server");
      });
  };

  return (
    <>
      <div className="register-img">
        <img
          src={domain + data?.data?.logo_image}
          className="w-[50%]"
          alt="register image"
        />
      </div>

      <div className="text-3xl text-white font-bold pb-8 mt-4">ĐĂNG NHẬP</div>

      <div className="mx-12">
        <InputGroupStyle
          name="username"
          label="Tên đăng nhập*"
          decoration={<FaUser size="2rem" className="text-bluelight" />}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputGroupStyle
          name="password"
          label="Mật khẩu*"
          type={typePassword ? "password" : "text"}
          decoration={<BiKey size="2rem" className="text-bluelight" />}
          decorationPW={
            typePassword ? (
              <FaRegEyeSlash
                size="2rem"
                color="grey"
                onClick={onChangeTypePW}
              />
            ) : (
              <FaRegEye size="2rem" color="grey" onClick={onChangeTypePW} />
            )
          }
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          variant="gradient"
          className="rounded-full py-4 text-2xl mt-16 button-style__account"
          onClick={handleClick}
          fullWidth
          ripple={true}
        >
          Đăng nhập
        </Button>
      </div>

      <div className="mt-8">
        <span className="mr-2 text-2xl">Chưa có tài khoản?</span>
        <Link to="/register" className="text-2xl text-[red]">
          Đăng kí ngay
        </Link>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </>
  );
}

export default Login;
