import NullData from "../../../../components/common/null_data";
import {
  format_money,
  convertAndFormatDateTime,
} from "../../../../utils/function";
import { list_keno5 } from "../../../../utils/const";
import { Table } from "antd";
import { BsClipboardData } from "react-icons/bs";
import { convertPhien } from "../../../../utils/function";
import dayjs from "dayjs";

export function HistoryBet({ data, dispatch, state }) {
  const handlePageChange = (page) => {
    dispatch({ action: "page", payload: page });
  };

  const columns = [
    {
      title: "Thời gian",
      dataIndex: "created_at",
      key: "created_at",
      render: (_) => (
        <div>
          <p>{dayjs(_).format("hh:mm:ss")}</p>
          <p>{dayjs(_).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Phiên",
      dataIndex: "phien",
      key: "phien",
      render: (phien) => <span>{convertPhien(phien)}</span>,
    },
    {
      title: "Số tiền",
      dataIndex: "money",
      key: "money",
      render: (money) => (
        <span className="whitespace-nowrap">{format_money(money)}</span>
      ),
    },
    {
      title: "Thắng",
      dataIndex: "win",
      key: "win",
      render: (win, record) =>
        !record.thanhtoan ? (
          <span className="text-blue-400 font-bold whitespace-nowrap">
            Chờ mở thưởng
          </span>
        ) : win > 0 ? (
          <span className="text-green-400 font-bold whitespace-nowrap">
            {format_money(win)}
          </span>
        ) : (
          <span className="text-red-400 font-bold whitespace-nowrap">0</span>
        ),
    },

    {
      title: "Dự án",
      dataIndex: "cuadat",
      key: "cuadat",
      render: (cuadat) => (
        <span className="font-bold text-blue-400">{list_keno5[cuadat]}</span>
      ),
    },
  ];

  const paginationConfig = {
    current: state.page,
    total: data?.total,
    pageSize: 50,
    onChange: handlePageChange,
    showSizeChanger: false,
  };

  return (
    <>
      <div
        className="overflow-x-auto scrollbar-hide" // Ẩn thanh cuộn nhưng vẫn cho phép cuộn
      >
        <Table
          dataSource={data?.data}
          columns={columns}
          pagination={paginationConfig}
          scroll={{ x: "max-content" }} // Scroll theo chiều ngang
          rowKey="id" // Assuming each item has a unique 'id'
        />
      </div>
    </>
  );
}
