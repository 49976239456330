import { configureStore } from "@reduxjs/toolkit";
import { apiUser } from "./apiUser";
import { apiAgency } from "./apiAgency";
import { rechargeApi } from "../api/rechargeApi";
import { savingBoxApi } from "../api/savingBoxApi";
import { vipApi } from "../api/vipApi";
import { apiAdmin } from "./apiAdmin";
import { setupListeners } from "@reduxjs/toolkit/query";

export const store = configureStore({
  reducer: {
    // Tạo thêm slice từ api
    [apiUser.reducerPath]: apiUser.reducer,
    [apiAgency.reducerPath]: apiAgency.reducer,
    [apiAdmin.reducerPath]: apiAdmin.reducer,
    [rechargeApi.reducerPath]: rechargeApi.reducer,
    [savingBoxApi.reducerPath]: savingBoxApi.reducer,
    [vipApi.reducerPath]: vipApi.reducer,
  },

  // Thêm cấu hình middleware để dùng được các chức năng của RTK Query như caching, invalidation, polling, ...
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiUser.middleware,
      rechargeApi.middleware,
      apiAdmin.middleware,
      apiAgency.middleware,
      savingBoxApi.middleware,
      vipApi.middleware
    ),
});

setupListeners(store.dispatch);

export default store;
