import React, { useState, useEffect, useRef } from "react";
import { useSpring, animated } from "react-spring";
import { formatNumber } from "../../../utils/function";

import "./keno_spin.css";
import "../../../pages/game/xucxac/xucxac.css";
const { screens, variables = {} } = require("tailwindcss/defaultTheme");

const getCustomVariable = (screen) => {
  switch (screen) {
    case "sm":
      return 4.75;
    case "md":
      return 5;
    case "lg":
      return 6.75;
    default:
      return 6.75; // Giá trị mặc định
  }
};

// Hàm để lấy loại màn hình hiện tại
const getCurrentScreen = () => {
  const width = window.innerWidth;
  if (width <= 360) {
    return "sm";
  } else if (width >= 361 && width <= 450) {
    return "md";
  } else {
    return "lg";
  }
};
function Kenospin({ message, type, time }) {
  const currentScreen = getCurrentScreen();
  const customVariable = getCustomVariable(currentScreen);
  console.log();

  const numberArray = Array.from({ length: 10 }, (_, index) => index);
  const list31 = [...numberArray, ...numberArray, ...numberArray, 1];
  const [lastResult, setLastResult] = useState([]);
  useEffect(() => {
    if (message?.lastResultKeno5Numbers?.ketqua) {
      setLastResult(message?.lastResultKeno5Numbers?.ketqua.split(","));
    }
  }, [message?.lastResultKeno5Numbers?.ketqua]);

  // console.log(message);

  return (
    <div className="keno-spin-box">
      <div className="keno-box-quay">
        <div className="keno-box">
          <div className="flex flex-row flex-wrap justify-between  w-full">
            {lastResult.map((item, index) => {
              return (
                <div
                  class="item-spin h-[10rem] md:h-[8.5rem] sm:h-[8rem]"
                  key={index}
                >
                  {/* <div
                    className={
                      time > type - 3
                        ? `span-container text-white roll`
                        : "span-container text-white"
                    }
                
                  > */}
                  {list31.map((num, i) => {
                    return (
                      <div
                        key={i}
                        className={`mb-[0.25rem] span-item w-[6.5rem] h-[6.5rem] text-[4.5rem] leading-[4.5rem] sm:w-[4.5rem] sm:h-[4.5rem] sm:text-[2.5rem] sm:leading-[2.5rem] md:w-[4.75rem] md:h-[4.75rem] md:text-[2.75rem] md:leading-[2.75rem] ${
                          time > type - 3 ? "roll" : ""
                        } ${
                          time < type - 3 && item == num
                            ? " bg-[#00e065] text-white"
                            : ""
                        }`}
                        style={{
                          transform: `translateY(${
                            1.75 - customVariable * item - customVariable * 20
                          }rem)`,
                          // transform: `translateY(0rem)`,
                          "--y": `${
                            1.75 - customVariable * item - customVariable * 20
                          }rem`,
                          "--time": `${2 + 0.2 * index}s`,
                        }}
                      >
                        {num}
                      </div>
                    );
                  })}
                  {/* </div> */}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Kenospin;
