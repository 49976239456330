import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import UseAnimations from "react-useanimations";
import alertTriangle from "react-useanimations/lib/alertTriangle";

function Dialog__TimeOut({ dialog, toggle }) {
  return (
    <>
      <Dialog open={dialog} handler={toggle} size="xs">
        <DialogBody>
          <div className="flex justify-center">
            <div className="w-15 h-15 rounded-full border-solid	border-2 border-red-500">
              <UseAnimations
                animation={alertTriangle}
                size={50}
                strokeColor="red"
              />
            </div>
          </div>
          <span className="block w-full text-center text-blue-600">
            Hết thời gian đặt cược
          </span>
        </DialogBody>
        <DialogFooter>
          <Button color="secondary" onClick={toggle}>
            Đóng
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default Dialog__TimeOut;
