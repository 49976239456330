import { today, today_year_ago } from "../../../utils/function";
import useWebSocket from "react-use-websocket";
import { useEffect, useState } from "react";
import { HeadingXoso } from "./components/headingxoso";
import Xoso_menugame from "../../../components/xoso_menugame";
import {
  useGetHistoryBetQuery,
  useGetUsersInfoQuery,
  useGetResultsHistoryQuery,
} from "../../../redux_toolkit/apiUser";
// import Modal_notice_win from "../../../components/modal/modal_notice_win/modal_notice_win";
import Dialog__SuccessBet from "../../../components/modal/Dialog_success_bet";
import Dialog__NoticeWin from "../../../components/modal/Dialog_notice_win";
import { useReducer } from "react";
import xoso_components from "../../../route/id_game";
import { useParams } from "react-router-dom";
import Loading from "../../../components/common/loading";
import config from "../../../config";
const socketUrl = config.REACT_APP_WEBSOCKET;
// const socketUrl = process.env.REACT_APP_WEBSOCKET;

const reducer = (state, { action, payload }) => {
  switch (action) {
    case "page":
      return { ...state, page: payload };
    default:
      return state;
  }
};

function Xoso(args) {
  const [loading, setLoading] = useState(true);
  const props = args.props.games;
  const { type } = useParams();
  const [tilecuoc, setTilecuoc] = useState({});
  const [tiletrathuong, setTiletrathuong] = useState({});
  const children = xoso_components.filter(item => item.path.includes(type))[0];
  // console.log(props);
  const [dataModalWin, setDataModalWin] = useState({});
  const [modalWin, setModalWin] = useState(false);
  const [DialogSuccessBet, setDialogSuccessBet] = useState(false);
  const [state, dispatch] = useReducer(reducer, {
    page: 1,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleModalWin = () => {
    setModalWin(!modalWin);
  };

  //Đăng nhập
  useEffect(() => {
    sendJsonMessage({ auth: { token: localStorage.getItem("auth") } });
  }, []);

  // const type = children.path.replace("/", "");
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  const [message, setMessage] = useState();
  useEffect(() => {
    sendJsonMessage({ xoso: { nextPhien: true, theloai: type } });
  }, [message?.xoso?.noticeData, message?.notice]);

  useEffect(() => {
    sendJsonMessage({ xoso: { getTiLe: true } });
  }, []);
  // console.log(message);
  useEffect(() => {
    if (lastMessage !== null) {
      const a = JSON.parse(lastMessage.data);
      // console.log(a);
      if (a?.xoso?.noticeData || a?.notice) {
        setMessage(a);
      } else {
        if (a?.xoso?.[type]) {
          setLoading(false);
          setMessage(a);
        }
        if (a?.xoso?.tile) {
          setTilecuoc(a?.xoso?.tile[1]);
          setTiletrathuong(a?.xoso?.tile[0]);
        }
      }
    }
  }, [lastMessage]);
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  const {
    data: dataResultsHistory,
    isLoading: isLoadingResultsHistory,
    isFetching: isFetchingResultHitory,
    refetch: refetchResultsHistory,
  } = useGetResultsHistoryQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "lottery",
      page: 1,
    },
    { refetchOnFocus: true },
  );

  const {
    data: dataHistoryBet,
    isLoading: isLoadingHistoryBet,
    isFetching: isFetchingHistoryBet,
    refetch: refetchHistoryBet,
  } = useGetHistoryBetQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "lottery",
      page: state.page,
    },
    { refetchOnFocus: true },
  );
  useEffect(() => {
    if (message?.notice) {
      refetchUserInfo();
      refetchHistoryBet();
      // console.log(dataHistoryBet?.data?.data);
    }
  }, [message?.notice]);

  useEffect(() => {
    if (message?.xoso?.noticeData) {
      refetchUserInfo();
      const a = Object.values(message?.xoso?.noticeData)[0];
      const data = Object.values(a)[0];
      setModalWin(true);
      setTimeout(() => {
        setModalWin(false);
      }, 3000);
      setDataModalWin(data);
    }
  }, [message?.xoso?.noticeData]);

  useEffect(() => {
    refetchResultsHistory();
    refetchHistoryBet();
  }, [message?.xoso?.[type]?.lastResult?.phien]);
  // console.log(message?.xoso.xsmb.nextPhien.timeEnd);

  return (
    <>
      <Loading
        loading={
          isLoadingHistoryBet || isFetchingUserInfo || isLoadingResultsHistory
        }
      />
      <HeadingXoso
        message={message}
        children={children}
        type={type}
        dataResultsHistory={dataResultsHistory}
        isLoadingHistoryBet={isFetchingHistoryBet}
        dataHistoryBet={dataHistoryBet}
        state={state}
        dispatch={dispatch}
      />
      <Xoso_menugame
        props={props}
        children={children}
        type={type}
        dataUserInfo={dataUserInfo}
        isLoadingUserInfo={isLoadingUserInfo}
        refetchUserInfo={refetchUserInfo}
        message={message}
        sendJsonMessage={sendJsonMessage}
        tilecuoc={tilecuoc}
        tiletrathuong={tiletrathuong}
      />

      <Dialog__NoticeWin
        data={dataModalWin}
        dialog={modalWin}
        toggle={toggleModalWin}
      />
      <Dialog__SuccessBet
        dialog={DialogSuccessBet}
        toggle={() => setDialogSuccessBet(!DialogSuccessBet)}
      />
    </>
  );
}

export default Xoso;
