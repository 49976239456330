import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function Dialog__Howplay_xx({ dialog, toggle }) {
  return (
    <>
      <Dialog open={dialog} handler={toggle} size="xs">
        <DialogHeader
          style={{ background: "red", borderRadius: "0.5rem 0.5rem 0 0" }}
        >
          <>
            <span className="mr-4 text-white">Cách chơi:</span>
          </>
        </DialogHeader>
        <DialogBody>
          <div className="text-[1.5rem] md:text-[1.3rem] sm:text-[1.2rem] text-black">
            <div className="flex flex-col mb-3">
              <span className="font-bold">CLTX</span>
              <span className="">
                Kết quả được tính là tổng của 3 con xúc xắc(Chẵn,lẻ,tài,xỉu)
              </span>
            </div>
            <div className="flex flex-col mb-3">
              <span className="font-bold">Tổng</span>
              <span className="">
                Kết quả được tính là tổng của 3 con xúc xắc
              </span>
            </div>
            <div className="flex flex-col mb-3">
              <span className="font-bold">Số đơn</span>
              <span className="">
                Kết quả được tính khi đổ xúc xắc có con số bạn chọn
              </span>
            </div>
            <div className="flex flex-col mb-3">
              <span className="font-bold">2 số trùng</span>
              <span className="">
                Kết quả được tính khi đổ xúc xắc có 2 con số giống nhau
              </span>
            </div>
            <div className="flex flex-col">
              <span className="font-bold">3 số trùng</span>
              <span className="">
                Kết quả được tính khi đổ xúc xắc có 3 con số giống nhau
              </span>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default Dialog__Howplay_xx;
