// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import "./modal.css";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import airplay from "react-useanimations/lib/airplay";
import UseAnimations from "react-useanimations";
import { format_money, formatTime } from "../../utils/function";
import "./modal.css";
import dayjs from "dayjs";
import { list_keno } from "../../utils/const";
import { list_xucxac } from "../../utils/const";

function Dialog__KenoResult({ data, dialog, toggle }) {
  return (
    <Dialog
      open={dialog}
      handler={toggle}
      size="sm"
      className="dialog_KenoResult"
    >
      <DialogHeader>
        <div className="flex items-center mt-4 ml-6 justify-center">
          <span className="mr-2">Kết quả phiên:</span>
          <span>{data.phien}</span>
        </div>
      </DialogHeader>
      <DialogBody>
        <div className="mx-2 bg-[aliceblue] p-2 rounded-lg text-blue-400">
          <div className="text-[1.3rem]  ">
            <span className="mr-2">Thể loại:</span>
            <span>{data.theloai}</span>
          </div>

          <div className="text-[1.3rem] ">
            <span className="mr-2">Mở thưởng lúc:</span>
            <span>{dayjs(data.created_at).format("HH:mm:ss")}</span>
          </div>
          <div className="text-[1.3rem]  flex font-bold">
            <span className="mr-2 w-fit">Kết quả:</span>
            <span className="mr-2">{data.tong}</span>
            <span>{`[${list_keno[data.taixiu]}-${list_keno[data.chanle]}-${
              list_keno[data.nguHanh]
            }-${list_keno[data.trenHoaDuoi]}]`}</span>
          </div>
          <div className="flex flex-row flex-wrap w-[24rem]">
            {data.result.split(",").map((item) => {
              return (
                <span className="w-7 h-7 bg-[red] shadow-sm text-white text-[1rem] rounded-full m-1 text-center leading-7 font-bold">
                  {item}
                </span>
              );
            })}
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button color="secondary" onClick={toggle}>
          Đóng
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default Dialog__KenoResult;
