import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import { useGetUsersInfoQuery } from "../redux_toolkit/apiUser";

export function item_box_1(title, type, description, img, link) {
  this.title = title;
  this.type = type;
  this.description = description;
  this.img = img;
  this.link = link;
}

export function item_box_2(title, img, path) {
  this.title = title;
  this.img = img;
  this.path = path;
}

export function item_5(name, code, type) {
  this.name = name;
  this.code = code;
  this.type = type;
}

export function item_box_3(path, img) {
  this.path = path;
  this.img = img;
}

export function item_4(name, icon, path, onClick) {
  this.name = name;
  this.icon = icon;
  this.path = path;
  this.onClick = onClick;
}

export function format_money(money) {
  return new Intl.NumberFormat().format(money) + "đ";
}

export function format_money_no_monetary_unit(money) {
  return new Intl.NumberFormat().format(money);
}

export function generateCombinations(
  arrays,
  currentIndex,
  currentCombination,
  resultArray
) {
  if (currentIndex === arrays.length) {
    // Khi đến cuối mảng, nối các phần tử và thêm vào mảng kết quả
    resultArray.push(currentCombination.join(""));
  } else {
    // Duyệt qua từng phần tử của mảng hiện tại
    for (var i = 0; i < arrays[currentIndex].length; i++) {
      // Gọi đệ quy để xử lý mảng tiếp theo
      generateCombinations(
        arrays,
        currentIndex + 1,
        currentCombination.concat(arrays[currentIndex][i]),
        resultArray
      );
    }
  }
}

//Kiểm tra chuỗi có ý tự chỉ gồm chữ số . dấu phẩy
export function checkInput(string) {
  for (let i = 0; i < string.length; i++) {
    const kyTu = string[i];
    if (!/[0-9, ]/.test(kyTu)) {
      return true; // Chuỗi chứa phần tử khác chữ số, dấu phẩy, hoặc dấu cách
    }
  }
  return false; // Chuỗi chỉ chứa chữ số, dấu phẩy, hoặc dấu cách
}

export function today() {
  const date = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear();

  return year + " " + month + " " + date + " 23:59:59";
}

export function today_year_ago() {
  const date = new Date().getDate();
  const month = new Date().getMonth() + 1;
  const year = new Date().getFullYear() - 1;

  return year + " " + month + " " + date + " 00:00:00";
}

export function formatTime(timeInSeconds) {
  if (timeInSeconds < 60) {
    return timeInSeconds + " giây";
  } else {
    var minutes = Math.floor(timeInSeconds / 60);
    var seconds = timeInSeconds % 60;
    if (seconds === 0) {
      return minutes + " phút";
    } else {
      return minutes + " phút " + seconds + " giây";
    }
  }
}

export function formatTime2(chuoiThoiDiem) {
  var thoiDiemUTC = new Date(chuoiThoiDiem);

  // Chuyển đổi múi giờ
  var options = { timeZone: "Asia/Ho_Chi_Minh" };
  var formatter = new Intl.DateTimeFormat("vi-VN", options);
  var thoiDiemVietNam = formatter.format(thoiDiemUTC);

  // Lấy giờ, phút, giây
  var gio = thoiDiemUTC.getHours();
  var phut = thoiDiemUTC.getMinutes();
  var giay = thoiDiemUTC.getSeconds();

  // Thêm vào chuỗi kết quả
  thoiDiemVietNam += " " + gio + ":" + phut + ":" + giay;

  return thoiDiemVietNam;
}

export function limitStringLength(str, maxLength) {
  if (str.length > maxLength) {
    return str.substring(0, maxLength) + "..."; // Giới hạn độ dài và thêm dấu elipsis (...).
  }
  return str;
}

export function convertAndFormatDateTime(utcDateTimeString) {
  // Tạo đối tượng Date từ chuỗi ngày tháng UTC
  let dateObject = new Date(utcDateTimeString);

  // Chuyển múi giờ về UTC+7 (Việt Nam)
  dateObject.setUTCHours(dateObject.getUTCHours());

  // Định dạng ngày và giờ
  let options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  let formattedDateTime = dateObject.toLocaleString("en-US", options);
  return formattedDateTime;
}

export function formatNumber(number, n) {
  // Chuyển số thành chuỗi
  let numberString = String(number);

  // Tính số lượng số 0 cần thêm vào
  let zerosToAdd = n - numberString.length;

  // Thêm số 0 đằng trước nếu cần
  if (zerosToAdd > 0) {
    const leadingZeros = "0".repeat(zerosToAdd);
    numberString = leadingZeros + numberString;
  }

  return numberString;
}

export function positive_negative_number(number) {
  if (number > 0) {
    return "+" + new Intl.NumberFormat().format(number);
  } else {
    return new Intl.NumberFormat().format(number);
  }
}

// export function checkToken({ status, message, msg }) {
//   if (
//     (status === "error" && message === "Invalid token") ||
//     (status === false, msg === "Failed token")
//   ) {
//     window.location.assign("/account/login");
//     sessionStorage.removeItem("auth");
//   }
// }
export function convertPhien(input) {
  const inputString = input.toString();
  if (inputString.length === 8) {
    return dayjs(inputString).format("DD/MM/YYYY");
  } else {
    return inputString.slice(8);
  }
}

export function numberTofixed(num) {
  let value = parseFloat(num);

  if (Number.isInteger(value)) {
    return value.toFixed(0);
  }

  return value.toFixed(1);
}

export function phien_to_ngay(phien) {
  phien = String(phien);
  // console.log(phien);
  // Tách năm, tháng, ngày từ chuỗi
  const nam = phien.substring(0, 4);
  const thang = phien.substring(4, 6);
  const ngay = phien.substring(6);

  // Tạo đối tượng Date từ các thành phần trích xuất
  const ngayThang = new Date(`${nam}-${thang}-${ngay}`);

  // Lấy thông tin ngày, tháng, năm từ đối tượng Date
  const ngayMoi = ngayThang.getDate();
  const thangMoi = ngayThang.getMonth() + 1;
  const namMoi = ngayThang.getFullYear();

  // Định dạng lại chuỗi ngày tháng
  const chuoiNgayMoi = `${ngayMoi}/${thangMoi}/${namMoi}`;

  return chuoiNgayMoi;
}

export const checkTokenLogin = () => {
  const accessToken = localStorage.getItem("auth");
  if (!accessToken) {
    localStorage.removeItem("auth");
    window.location.href = "/login";
  }
};

export const checkTokenApi = ({ status, message }) => {
  if (status === "error" && message === "Invalid token") {
    window.location.assign("/login");
    localStorage.clear();
  }
};

export const checkToken = ({ status, msg }) => {
  if (status === false) {
    window.location.assign("/account/login");
    localStorage.removeItem("auth");
    return toast.error(msg, "light");
  }
};

//kiểm tra 1 chuỗi có phải số hay không
export function isNumeric(str) {
  return !isNaN(str);
}

//format thời gian khi vượt quá 3 chữ số
export function formatNumber3(number) {
  // Chuyển số thành chuỗi
  const numString = String(number);
  // console.log(numString);

  // Kiểm tra độ dài của chuỗi số
  if (numString.length === 1) {
    // Nếu chỉ có 1 chữ số, thêm "0" phía trước
    return "0" + numString;
  } else if (numString.length <= 3) {
    // Nếu có từ 2 đến 3 chữ số, không cần thêm "0"
    return numString;
  } else {
    // Nếu có nhiều hơn 3 chữ số, giữ lại chỉ 3 chữ số đầu tiên
    return numString.slice(0, 3);
  }
}

export function kiemTraThoiGian(time) {
  // Kiểm tra xem thời gian có lớn hơn 0 không
  if (typeof time === "number" && time > 0) {
    // Nếu lớn hơn 0, trả về thời gian
    return time;
  } else {
    // Nếu không lớn hơn 0, trả về 0
    return 0;
  }
}

export function format_time_table_taixiu(chuoiThoiGian) {
  // Tạo đối tượng Date từ chuỗi thời gian
  const thoiGian = new Date(chuoiThoiGian);

  // Chuyển đổi thời gian sang múi giờ Việt Nam (ICT)
  thoiGian.setHours(thoiGian.getHours()); // UTC+7 cho múi giờ Việt Nam

  // Lấy thông tin ngày, tháng, giờ, phút, giây từ đối tượng Date
  const ngay = thoiGian.getDate();
  const thang = thoiGian.getMonth() + 1; // Tháng trong đối tượng Date bắt đầu từ 0
  const gio = thoiGian.getHours();
  const phut = thoiGian.getMinutes();
  const giay = thoiGian.getSeconds();

  // Định dạng lại chuỗi theo ngày-tháng giờ-phút-giây
  const chuoiDaChuyenDoi = `${ngay < 10 ? "0" : ""}${ngay}/${
    thang < 10 ? "0" : ""
  }${thang} ${gio < 10 ? "0" : ""}${gio}:${phut < 10 ? "0" : ""}${phut}:${
    giay < 10 ? "0" : ""
  }${giay}`;

  return chuoiDaChuyenDoi;
}

// chuyển thể loại
export function formattheloai(str) {
  const number = parseFloat(str);
  if (!isNaN(number)) {
    // Kiểm tra xem chuỗi có phải là một số hay không
    const minutes = Math.floor(number / 60);
    const seconds = Math.floor(number % 60);
    if (minutes === 0) {
      return `${seconds} giây`;
    } else if (seconds === 0) {
      return `${minutes} phút`;
    } else {
      return `${minutes} phút ${seconds} giây`;
    }
  } else {
    return str; // Trả về chuỗi ban đầu nếu không phải là một số
  }
}

export function CheckSameElement(arrA, arrB) {
  // Tạo một Set từ mảng A để tối ưu việc kiểm tra tồn tại
  let setA = new Set(arrA);
  // Kiểm tra từng phần tử của mảng B
  for (let i = 0; i < arrB.length; i++) {
    // Nếu phần tử của mảng B tồn tại trong mảng A
    if (setA.has(arrB[i])) {
      return true; // Có phần tử chung, trả về true
    }
  }

  // Nếu không có phần tử chung
  return false;
}

export function validateUsernamePassword(str) {
  const regex = /^[a-zA-Z0-9]+$/;
  if (!regex.test(str)) {
    return false;
  }

  return true;
}

export function validatePhone(phone) {
  const phoneRegex = /^0\d{9}$/;
  if (!phoneRegex.test(phone)) {
    return false;
  }

  return true;
}
