import PropTypes from "prop-types";

function Title({ name = "" }) {
  return (
    <div className="text-white font-bold text-5xl py-10 relative z-50">
      {name}
    </div>
  );
}

Title.propTypes = {
  name: PropTypes.string,
};

export default Title;
