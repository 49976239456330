// import logo from "./logo.svg";
import "./App.css";
import "./index.css";
// import Test from "./components/Test";
import { Fragment, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import publicRoutes from "./route";
import Layout from "./components/Layout";
import { LiveChatWidget } from "@livechat/widget-react";
import { Helmet } from "react-helmet";
import {
  useGetSettingQuery,
  useGetUsersInfoQuery,
} from "./redux_toolkit/apiUser";
import config from "./config";

const domain = config.REACT_APP_DOMAIN;
// const domain = process.env.REACT_APP_DOMAIN;
const routeNotCheck = ["/login", "/register", "/"];
const path_public = ["/login", "/register"];

function App() {
  const { data: dataSetting, isLoading: isLoadingSetting } =
    useGetSettingQuery();
  // const {
  //   data: dataUser,
  //   isLoading: isLoadingUser,
  //   refetch,
  //   error,
  // } = useGetUsersInfoQuery();

  // useEffect(() => {
  //   refetch();
  // }, [location.pathname]);

  const [title, setTitle] = useState("");
  const [favicon, setFavicon] = useState("");
  const [chatid, setChatid] = useState();

  useEffect(() => {
    if (dataSetting) {
      setTitle(dataSetting?.data?.title);
      setFavicon(dataSetting?.data?.favicon);
      setChatid(dataSetting?.data?.id_livechat);
    }
  }, [isLoadingSetting]);

  return (
    <>
      <Helmet>
        <link
          rel="icon"
          type="image/png"
          href={domain + favicon}
          sizes="16x16"
        />
        <title>{title}</title>
      </Helmet>
      <Router>
        <div className="App">
          <Routes>
            {publicRoutes.map((route, index) => {
              let Page_Layout = Layout;
              if (route.layout) {
                Page_Layout = route.layout;
              } else if (route.layout === null) {
                Page_Layout = Fragment;
              }
              const Page = route.component;
              if (route.childrens) {
                return route.childrens.map(children => {
                  return (
                    <Route
                      key={route.path + children.path}
                      path={route.path + children.path}
                      element={
                        <Page_Layout>
                          <Page
                            link={route.path + children.path}
                            title={route.title}
                            props={route.games}
                            children={children}
                          />
                        </Page_Layout>
                      }
                      exact
                    />
                  );
                });
              } else {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    element={
                      <Page_Layout>
                        <Page
                          link={route.path}
                          title={route.title}
                          props={route}
                        />
                      </Page_Layout>
                    }
                    exact
                  />
                );
              }
            })}
          </Routes>
        </div>
      </Router>
      {/*
{chatid && <LiveChatWidget license={chatid} />}
     */}
    </>
  );
}

export default App;
