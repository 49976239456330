import { Link } from "react-router-dom";
import "./xsmt.css";
import { useState } from "react";
import xoso_components from "../../../route/id_game";

function Xsmt(arg) {
  const today = new Date().getDay();

  const list_days = [
    "Chủ Nhật",
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7",
  ];
  const [daySelected, setDaySeLected] = useState(list_days[today]);
  const list_province = xoso_components.filter((item) =>
    item.menu_name.includes("Miền Trung")
  );
  const handleSelectChange = (e) => {
    setDaySeLected(e.target.value);
  };
  console.log(daySelected);

  return (
    <>
      <h2 className="my-[3rem] text-white font-bold text-[3.5rem] relative z-10">
        Xổ số miền Trung
      </h2>
      <div className="flex justify-center mb-6 relative z-10">
        <div className="mt-select-day shadow">
          <span>Chọn ngày xổ</span>
          <select value={daySelected} onChange={handleSelectChange}>
            {list_days.map((item, i) => {
              return (
                <option key={i} value={item}>
                  {item}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="flex flex-col w-[90%] m-auto">
        {list_province.map((item) => {
          return (
            <>
              {item.days.includes(list_days.indexOf(daySelected)) && (
                <Link
                  to={`/xoso${item.path}`}
                  key={item.menu_name}
                  className="mt-province shadow"
                >
                  <span>{item.menu_name.replace("Miền Trung", "")}</span>
                  <img className="w-28 h-28" src={item.img} />
                </Link>
              )}
            </>
          );
        })}
      </div>
    </>
  );
}

export default Xsmt;
