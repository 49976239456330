import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkTokenApi } from "../utils/function";
import config from "../config";

const domain = config.REACT_APP_DOMAIN;
// const domain = process.env.REACT_APP_DOMAIN;

export const savingBoxApi = createApi({
  reducerPath: "savingBoxApi",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/user" }),

  endpoints: builder => ({
    getDetailSavingBox: builder.query({
      query: () => ({
        url: `/save-box`,
        headers: headers(),
        method: "GET",
      }),
      transformResponse: res => {
        checkTokenApi(res);
        return res.data;
      },
    }),
    getHistorySavingBox: builder.query({
      query: params => ({
        url: `/save-box/history`,
        headers: headers(),
        params: params,
        method: "GET",
      }),
      transformResponse: res => {
        checkTokenApi(res);
        return res.data;
      },
    }),

    addSaving: builder.mutation({
      query: params => ({
        url: `/save-box`,
        headers: headers(),
        body: params,
        method: "POST",
      }),
      transformResponse: res => {
        checkTokenApi(res);
        return res.data;
      },
    }),
  }),
});

export const {
  useGetDetailSavingBoxQuery,
  useGetHistorySavingBoxQuery,
  useAddSavingMutation,
} = savingBoxApi;
