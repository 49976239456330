import { useEffect, useState } from "react";
import isEmpty from "validator/lib/isEmpty";
import "./table_xoso.css";
import { ToastContainer, toast } from "react-toastify";
import {
  checkInput,
  generateCombinations,
  formatNumber,
} from "../../../utils/function";

import Select_number from "./select_number";
import Confirm_bet from "./confirm_bet";
// import Modal_not_enough_money from "../../modal/Dialog_not_enough_money.js";
// import Modal_success_bet from "../../modal/Dialog_success_bet.js";
// import Modal_select_enough_number from "../../modal/Dialog_select_enough_number.js";
// import Modal_time_out from "../../modal/Dialog_time_out.js";
// import Modal_confirm_info_bet from "../../modal/Dialog_confirm_info_bet.js";
import {
  format_money_no_monetary_unit,
  CheckSameElement,
} from "../../../utils/function";
import Dacsac_thuvi from "./select_number/dacsac_thuvi";
import Dialog_confirm_info_bet from "../../modal/Dialog_confirm_info_bet.js";
import Dialog__NotEnoughMoney from "../../modal/Dialog_not_enough_money.js";
import Dialog__TimeOut from "../../modal/Dialog_time_out.js";
import Dialog__SuccessBet from "../../modal/Dialog_success_bet.js";
import Dialog__NotLogin from "../../modal/Dialog_Not_login.js";
import Dialog__SelectEnoughNumber from "../../modal/Dialog_select_enough_number.js";
function Choose(name, hang, func) {
  this.name = name;
  this.hang = hang;
  this.func = func;
}

function Xoso_bet({
  props_child,
  tab1,
  tab2,
  dataUserInfo,
  isLoadingUserInfo,
  refetchUserInfo,
  message,
  sendJsonMessage,
  type,
  time,
  game,
  tilecuoc,
  tiletrathuong,
}) {
  // console.log(props_child[0]);
  const [numberSelected, setNumberSelected] = useState([]);
  const [pointBet, setPointBet] = useState("");
  const [countSelected, setCountSelected] = useState(0);
  const [dialogNotLogin, setDialogNotLogin] = useState(false);

  // state types select
  const [typeSeclect, setTypeSelect] = useState("Chọn số");
  const [chonNhanhValue, setChonNhanhValue] = useState("");
  const [donvi, setDonvi] = useState([]);
  const [chuc, setChuc] = useState([]);
  const [tram, setTram] = useState([]);
  const [nghin, setNghin] = useState([]);

  // constant
  const hangs = [
    new Choose("Nghìn", nghin, setNghin),
    new Choose("Trăm", tram, setTram),
    new Choose("Chục", chuc, setChuc),
    new Choose("Đơn vị", donvi, setDonvi),
  ].slice(-props_child.char);

  // check số có quá số ký tự hay không
  const isMaxCharacters = (element) => {
    return element.length <= props_child.char;
  };

  //xử lí chọn số
  const handleUnit = (func, hang, item) => {
    // console.log(func, hang, item);
    if (hang.includes(item)) {
      return func(
        hang.filter((num) => {
          return num !== item;
        })
      );
    } else {
      return func([...hang, item]);
    }
  };

  useEffect(() => {
    setCountSelected(0);
    setNumberSelected([]);
    setDonvi([]);
    setChuc([]);
    setTram([]);
    setNghin([]);
    setPointBet("");
    setChonNhanhValue("");
  }, [game]);

  const func1 = (data) => {
    const arr1 = [];
    const arr_result = [];
    for (var i = 0; i < data.length; i++) {
      if (data[i].hang.length === 0) {
        return;
      } else {
        arr1.push(data[i].hang);
      }
    }
    generateCombinations(arr1, 0, [], arr_result);
    return arr_result;
  };

  // xử lí nút xác nhận
  const handleSelectNumber = (arr) => {
    if (typeSeclect === "Chọn số") {
      const arr_result = func1(hangs);
      // console.log(arr_result);
      if (!Array.isArray(arr_result)) {
        toast.error("Hãy chọn đủ chữ số");
      } else {
        setDonvi([]);
        setChuc([]);
        setTram([]);
        setNghin([]);
        console.log(arr_result);
        if (CheckSameElement(numberSelected, arr_result)) {
          toast.error("Số vừa chọn đã trùng");
        } else {
          setNumberSelected(numberSelected.concat(arr_result));
          setCountSelected(countSelected + arr_result.length);
        }
      }
    } else {
      if (checkInput(chonNhanhValue)) {
        toast.error("Ký tự không hợp lệ");
      } else {
        //chuỗi -> array , chia bởi dấu "," hoặc dấu cách. Nếu có phần tử chuỗi rỗng thì loại bỏ
        const arr = chonNhanhValue.split(/[,\s]+/).filter(Boolean);

        // kiểm tra các phần tử của chuỗi có vượt quá số ký tự không
        const condition = arr.every(isMaxCharacters);
        if (!condition) {
          toast.error("Số không hợp lệ");
        } else {
          const b = arr.map((item) => {
            return formatNumber(item, props_child.char);
          });
          console.log(b);
          if (CheckSameElement(numberSelected, b)) {
            toast.error("Số vừa chọn đã trùng");
          } else {
            setNumberSelected(numberSelected.concat(b));
            setCountSelected(countSelected + b.length);
          }
          setChonNhanhValue("");
        }
      }
    }
  };

  //xử lí chọn nhanh
  const choose_number = (number) => {
    if (numberSelected.includes(number)) {
      setNumberSelected(numberSelected.filter((item) => item !== number));
      setCountSelected(countSelected - 1);
    } else {
      setNumberSelected([...numberSelected, number]);
      setCountSelected(countSelected + 1);
    }
  };

  useEffect(() => {
    console.log(typeof numberSelected, numberSelected);
  }, [numberSelected]);

  //modal min
  const [modalSelectEnoughNumber, setModalSelectEnoughNumber] = useState(false);
  const toggle_select_enough_number = () => setModalSelectEnoughNumber(false);
  //modal success
  const [modalSuccess, setModalSuccess] = useState(false);
  const toggle_success = () => {
    setModalSuccess(!modalSuccess);
    setCountSelected(0);
    setNumberSelected([]);
    setDonvi([]);
    setChuc([]);
    setTram([]);
    setNghin([]);
    setPointBet("");
    setChonNhanhValue("");
  };

  //modal not enough money
  const [modalNotEnoughMoney, setModalNotEnoughMoney] = useState(false);
  const toggle_not_enough_money = () => setModalNotEnoughMoney(false);

  //modal time out
  const [modalTimeOut, setModalTimeOut] = useState(false);
  const toggle_time_out = () => setModalTimeOut(false);

  //modal confirm info bet
  const [modalConfirmInfoBet, setModalConfirmInfoBet] = useState(false);
  const toggleConfirm = () => {
    console.log("2222");
    console.log(message?.xoso);
    if (time < 6) {
      setModalTimeOut(true);
    } else if (time > 5) {
      console.log("11");
      if ((game = "Lô")) {
        sendJsonMessage({
          xoso: {
            cuoc: {
              theloai: type,
              so: numberSelected.join(","),
              sodiem: pointBet * 1000,
              type: props_child.code,
            },
          },
        });
      } else {
        sendJsonMessage({
          xoso: {
            cuoc: {
              theloai: type,
              so: numberSelected.join(","),
              sodiem: pointBet,
              type: props_child.code,
            },
          },
        });
      }
    } else if (message?.xoso?.[type].nextPhien) {
      console.log("xsmb");
      const now = new Date();
      const distance = message.xoso[type].nextPhien.timeEnd - now.getTime();
      if (distance < 0) {
        setModalTimeOut(true);
        console.log("hihi");
      } else {
        if ((game = "Lô")) {
          sendJsonMessage({
            xoso: {
              cuoc: {
                theloai: type,
                so: numberSelected.join(","),
                sodiem: pointBet * 1000,
                type: props_child.code,
              },
            },
          });
        } else {
          sendJsonMessage({
            xoso: {
              cuoc: {
                theloai: type,
                so: numberSelected.join(","),
                sodiem: pointBet,
                type: props_child.code,
              },
            },
          });
        }
      }
    }
    setModalConfirmInfoBet(false);
  };
  const toggleClose = () => setModalConfirmInfoBet(false);

  // xử lí nút reset
  const handleReset = () => {
    setNumberSelected([]);
    setCountSelected(0);
  };

  //cập nhật giá trị cho 2 ô input
  const handleChange = (event) => {
    setPointBet(event.target.value);
  };
  const handleChangeChonNhanh = (event) => {
    setChonNhanhValue(event.target.value);
  };

  //Chọn type chọn số
  const handleSelectType = (name) => {
    if (typeSeclect !== name) {
      setTypeSelect(name);
      setCountSelected(0);
      setNumberSelected([]);
      setDonvi([]);
      setChuc([]);
      setTram([]);
      setNghin([]);
      setPointBet("");
      setChonNhanhValue("");
    } else {
      return;
    }
  };
  // console.log(countSelected);

  //Xử lí nút đặt lệnh
  const handleClick = () => {
    if (!dataUserInfo || dataUserInfo?.status === "error") {
      setDialogNotLogin(true);
      return;
    } else if (numberSelected.length === 0) {
      toast.warning("Vui lòng chọn số");
      return;
    } else if (props_child.count && props_child.count !== countSelected) {
      // console.log("1");
      setModalSelectEnoughNumber(true);
      return;
    } else if (isEmpty(pointBet)) {
      toast.warning("Chưa nhập điểm cược");
      return;
    } else if (game !== "Lô" && pointBet < 1000) {
      toast.warning("Tiền cược tối thiểu 1.000");
      return;
    } else if (game === "Lô" && pointBet < 1) {
      toast.warning("Điểm cược tối thiểu 1");
      return;
    } else if (
      countSelected * pointBet * tilecuoc[props_child.code] >
      dataUserInfo.money
    ) {
      console.log(props_child.bet);
      setModalNotEnoughMoney(true);
      return;
    } else {
      // console.log("0000");
      if (time < 6) {
        console.log(3);
        setModalTimeOut(true);
      } else if (message?.xoso?.[type]?.nextPhien) {
        console.log(4);
        const now = new Date();
        const distance = message.xoso[type].nextPhien.timeEnd - now.getTime();
        if (distance < 0) {
          console.log(5);
          setModalTimeOut(true);
          console.log("hihi");
        } else {
          console.log(6);
          // setModalConfirmInfoBet(true);
          if ((game = "Lô")) {
            sendJsonMessage({
              xoso: {
                cuoc: {
                  theloai: type,
                  so: numberSelected.join(","),
                  sodiem: pointBet * 1000,
                  type: props_child.code,
                },
              },
            });
          } else {
            sendJsonMessage({
              xoso: {
                cuoc: {
                  theloai: type,
                  so: numberSelected.join(","),
                  sodiem: pointBet,
                  type: props_child.code,
                },
              },
            });
          }
        }
      } else {
        console.log(7);
        // setModalConfirmInfoBet(true);
        if ((game = "Lô")) {
          sendJsonMessage({
            xoso: {
              cuoc: {
                theloai: type,
                so: numberSelected.join(","),
                sodiem: pointBet * 1000,
                type: props_child.code,
              },
            },
          });
        } else {
          sendJsonMessage({
            xoso: {
              cuoc: {
                theloai: type,
                so: numberSelected.join(","),
                sodiem: pointBet,
                type: props_child.code,
              },
            },
          });
        }
      }
    }
  };

  useEffect(() => {
    if (message?.notice) {
      if (message.notice.msg === "Cuoc thanh cong!") {
        setModalSuccess(true);
        setTimeout(() => {
          setCountSelected(0);
          setNumberSelected([]);
          setDonvi([]);
          setChuc([]);
          setTram([]);
          setNghin([]);
          setPointBet("");
          setChonNhanhValue("");
          setModalSuccess(false);
        }, 3000);
        return;
      } else if (message.notice.msg === "Đăng nhập thành công") {
        return;
      } else if (message.notice.msg === "Bạn đã bị khoá cược!") {
        toast.error("Bạn đã bị khóa cược");
        return;
      } else if (message.notice.msg === "Tài khoản đã bị khóa!") {
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        toast.error("Tài khoản đã bị khóa");
        setTimeout(() => {
          window.location.assign("/login");
        }, 2000);
      }
    }
  }, [message?.notice]);

  const string_display = "";

  return (
    <>
      <Confirm_bet
        props={props_child}
        pointBet={pointBet}
        handleChange={handleChange}
        handleClick={handleClick}
        countSelected={countSelected}
        tab1={tab1}
        tab2={tab2}
        handleReset={handleReset}
        numberSelected={numberSelected}
        handleNhapnhanh={setPointBet}
        tiletrathuong={tiletrathuong}
        tilecuoc={tilecuoc}
        game={game}
      />

      {Select_number(
        typeSeclect,
        handleSelectType,
        handleSelectNumber,
        hangs,
        handleUnit,
        choose_number,
        handleChangeChonNhanh,
        chonNhanhValue,
        props_child,
        numberSelected,
        tab2,
        game
      )}
      {/* {!["Trò đặc sắc", "Trò chơi thú vị"].includes(tab1) &&
        Select_number(
          typeSeclect,
          handleSelectType,
          handleSelectNumber,
          hangs,
          handleUnit,
          choose_number,
          handleChangeChonNhanh,
          chonNhanhValue,
          props_child,
          numberSelected,
          tab2
        )}
      {["Trò đặc sắc", "Trò chơi thú vị"].includes(tab1) &&
        Dacsac_thuvi(
          typeSeclect,
          handleSelectType,
          handleSelectNumber,
          hangs,
          handleUnit,
          choose_number,
          handleChangeChonNhanh,
          chonNhanhValue,
          props_child,
          numberSelected,
          tab1,
          tab2
        )} */}

      <Dialog__SelectEnoughNumber
        dialog={modalSelectEnoughNumber}
        number={props_child.count}
        toggle={toggle_select_enough_number}
      />
      <Dialog__SuccessBet dialog={modalSuccess} toggle={toggle_success} />
      <Dialog__NotEnoughMoney
        dialog={modalNotEnoughMoney}
        toggle={toggle_not_enough_money}
      />
      <Dialog__TimeOut dialog={modalTimeOut} toggle={toggle_time_out} />
      <Dialog_confirm_info_bet
        dialog={modalConfirmInfoBet}
        toggle1={toggleConfirm}
        toggle2={toggleClose}
        tab={tab2}
        point={pointBet}
        numbers={numberSelected}
        count={countSelected}
      />
      <Dialog__NotLogin
        dialog={dialogNotLogin}
        toggle={() => setDialogNotLogin(!dialogNotLogin)}
      />
    </>
  );
}

export default Xoso_bet;
