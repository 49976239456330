function Custom_btn({
  color,
  text,
  top,
  bottom,
  right,
  left,
  onClick,
  zIndex,
  textColol,
}) {
  const style_btn = {
    background: color,
    padding: "0.2rem 0.5rem",
    borderRadius: "0.5rem",
    position: "absolute",
    top: top,
    bottom: bottom,
    left: left,
    right: right,
    zIndex: zIndex,
    fontSize: "1.2rem",
    cursor: "pointer",
    color: textColol,
  };
  return (
    <button style={style_btn} onClick={onClick}>
      {text}
    </button>
  );
}

export default Custom_btn;
