import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Submit_btn from "../common/submit_btn";

function Dialog__NotLogin({ dialog, toggle }) {
  const handleLogin = () => {
    window.location.assign("/login");
    localStorage.removeItem("auth");
  };
  return (
    <>
      <Dialog open={dialog} handler={toggle} size="sm">
        <DialogHeader className="flex justify-end">
          <Button onClick={toggle}>Đóng</Button>
        </DialogHeader>
        <DialogBody>
          <div className="flex items-center justify-center flex-col pb-10">
            <AiOutlineCloseCircle
              color="#f27474"
              className={`icon ${dialog && "icon-big"}`}
            />
            <h3 className="text-[27px] text-[#595959] font-bold">Thất bại</h3>
            <span className="text-[1.5rem]">Bạn chưa đăng nhập !</span>
            <div className="">
              <Submit_btn text="Đăng nhập ngay" onClick={handleLogin} />
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default Dialog__NotLogin;
