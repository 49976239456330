import { useEffect, useState } from "react";
import "../components.css";
import {
  useGetListBankQuery,
  useAddRechargeMutation,
} from "../../api/rechargeApi";
import { useGetUsersInfoQuery } from "../../redux_toolkit/apiUser";
import { InputGroupStyle } from "../../components/input/input";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import { format_money } from "../../utils/function";
import Loading from "../../components/common/loading";
import { checkTokenLogin } from "../../utils/function";
import qr from "../../assets/images/QR.png";
import qr2 from "../../assets/images/QR2.jpg";
import CurrencyInput from "react-currency-input-field";

export function formatCurrency(currency) {
  return new Intl.NumberFormat("de-DE").format(currency);
}

function Recharge(args) {
  useEffect(() => {
    checkTokenLogin();
  }, []);
  const {
    data: dataListBank,
    isLoading: isLoadingDataListBank,
    isFetching: isFetchingDataListBank,
  } = useGetListBankQuery();

  const {
    data: dataUser,
    isLoading: isLoadingDataUser,
    isFetching: isFetchingDataUser,
  } = useGetUsersInfoQuery();

  const [addRecharge, { data: res }] = useAddRechargeMutation();

  const [open, setOpen] = useState(false);
  const [rechargeBank, setRechargeBank] = useState();
  // const [money, setMoney] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [money, setMoney] = useState("");

  const validateValue = (value) => {
    const money = value === undefined ? "undefined" : value;
    setMoney(money || " ");

    if (!value) {
      // setClassName("");
    } else if (Number.isNaN(Number(value))) {
      setErrorMessage("Please enter a valid number");
      // setClassName("is-invalid");
    } else {
      // setClassName("is-valid");
    }
  };

  console.log(money);

  const handleOpen = () => setOpen(!open);

  useEffect(() => {
    if (dataListBank && !isLoadingDataListBank) {
      setRechargeBank(
        dataListBank[Math.floor(Math.random() * dataListBank.length)]
      );
    }
  }, [isLoadingDataListBank]);

  const handleClickCopySTK = () => {
    if (rechargeBank) {
      navigator.clipboard.writeText(rechargeBank?.bank_number);
      toast.success("Đã sao chép số tài khoản!", "light");
    }
  };
  const handleClickCopyBank = () => {
    if (rechargeBank) {
      navigator.clipboard.writeText(rechargeBank?.bank);
      toast.success("Đã sao chép Tên ngân hàng!", "light");
    }
  };
  const handleClickCopyUser = () => {
    if (rechargeBank) {
      navigator.clipboard.writeText(rechargeBank?.bank_account);
      toast.success("Đã sao chép người nhận!", "light");
    }
  };

  const handleClick = () => {
    setOpen(false);
    if (rechargeBank) {
      if (money > 0) {
        addRecharge({
          money: money,
          namebank: rechargeBank.bank,
          stk: rechargeBank.bank_number,
          chutk: rechargeBank.bank_account,
          noidung: dataUser.username,
          id_km: null,
        });
      } else {
        toast.warn("Số tiền không hợp lệ!", "light");
      }
    } else {
      toast.warn("Thông tin ngân hàng không hợp lệ!", "light");
    }
  };

  useEffect(() => {
    if (res) {
      if (res.status === true) {
        toast.success(res.msg);
      } else if (res.status === false) {
        toast.error(res.msg);
      }
    }
  }, [res]);

  return (
    <>
      <Loading loading={isLoadingDataListBank} />
      <div className="components-body relative z-[4]">
        <h1 className="components-title">Nạp tiền</h1>
        <div className="recharge-bank-info relative w-[90%] m-auto mt-[8rem]">
          <div className="flex justify-between">
            <div className="flex text-left px-1">
              <span className="text-gray-500 text-2xl mr-2">STK:</span>
              <span className="text-black text-2xl font-bold">
                {rechargeBank?.bank_number}
              </span>
            </div>
            <span className="copy-stk" onClick={handleClickCopySTK}>
              Copy STK
            </span>
          </div>
          <div className="flex justify-between my-2">
            <div className="flex text-left px-1">
              <span className="text-gray-500 text-2xl pr-[0.2rem]">
                Ngân hàng:
              </span>
              <span className="text-black text-2xl font-bold">
                {rechargeBank?.bank}
              </span>
            </div>
            <span className="copy-stk" onClick={handleClickCopyBank}>
              Copy Ngân hàng
            </span>
          </div>
          <div className="flex justify-between">
            <div className="flex text-left px-1">
              <span className="text-gray-500 text-2xl mr-2">Người nhận:</span>
              <span className="text-black text-2xl font-bold">
                {rechargeBank?.bank_account}
              </span>
            </div>
            <span className="copy-stk" onClick={handleClickCopyUser}>
              Copy Người nhận
            </span>
          </div>

          <div className="flex text-left px-1">
            <span className="text-gray-500 text-2xl mr-2">
              Nội dung chuyển khoản:
            </span>
            <span className="text-black text-2xl font-bold">
              {`${dataUser?.username}`}
            </span>
          </div>
        </div>
        <div className="flex justify-center items-center mt-8">
          <img src={rechargeBank?.qr_code} className="w-[50%] h-auto" />
          {/* <img src={qr2} className="w-[50%] h-auto" /> */}
        </div>
        <div className="mx-2 mt-8">
          <CurrencyInput
            id="validation-example-2-field"
            placeholder="Nhập số tiền"
            allowDecimals={false}
            maxLength={12}
            className="w-full py-4 px-8 rounded-full"
            onValueChange={validateValue}
            suffix={" VND"}
            step={10}
          />
          {/* <InputGroupStyle
            name="money"
            label="Nhập số tiền"
            decorationClassName="hidden"
            type="number"
            isBorderRadius={true}
            value={formatCurrency(money)}
            onChange={(e) => {
              setMoney(e.target.value);
            }}
            // onChange={(e) => setMoney(e.target.value)}
            // onChange={(e) => {
            //   const value = e.target.value;
            //   const regex = /^\d{1,3}(,\d{3})*$/;
            //   if (regex.test(value) && Number(value.replace(/,/g, "")) > 0) {
            //     setMoney(value);
            //   }
            // }}
          /> */}
          <Button
            variant="gradient"
            className="rounded-full py-4 text-2xl mt-8 button-style__account"
            onClick={handleClick}
            fullWidth
            ripple={true}
          >
            Xác nhận
          </Button>
        </div>
        <div className="components-content p-2 mt-8 mx-2">
          <h2 className="components-content-title">Hướng dẫn nạp tiền</h2>
          <ul className="mt-4">
            <li>Chuyển khoản đến thông tin ngân hàng ở trên.</li>
            {/* <li>
              Vui lòng ghi đúng nội dung chuyển khoản là tên đăng nhập của bạn.
            </li> */}
            <li>
              Sau khi chuyển khoản thành công quý khách điền số tiền đã chuyển
              khoản vào ô "Nhập số tiền" và bấm xác nhận, số điểm sẽ được cộng
              trong vòng 3 phút.
            </li>
          </ul>
        </div>

        <Dialog
          open={open}
          handler={handleOpen}
          animate={{
            mount: { scale: 1, y: 0 },
            unmount: { scale: 0.9, y: -100 },
          }}
        >
          <DialogHeader>NẠP TIỀN</DialogHeader>
          <DialogBody className="text-xl">
            <div className="font-semibold mb-4">
              VUI LÒNG KIỂM TRA LẠI THÔNG TIN CHUYỂN KHOẢN
            </div>
            <div className="mb-4">
              <span className="mr-2">SỐ TÀI KHOẢN:</span>
              <span className="font-semibold">{rechargeBank?.bank_number}</span>
            </div>
            <div className="mb-4">
              <span className="mr-2">NGÂN HÀNG:</span>
              <span className="font-semibold">{rechargeBank?.bank}</span>
            </div>
            <div className="mb-4">
              <span className="mr-2">NGƯỜI NHẬN:</span>
              <span className="font-semibold">
                {rechargeBank?.bank_account}
              </span>
            </div>
            <div className="mb-4">
              <span className="mr-2">SỐ TIỀN NẠP:</span>
              <span className="font-semibold">{format_money(money)}</span>
            </div>
            <div className="mb-4">
              <span className="mr-2">NỘI DUNG CHUYỂN KHOẢN:</span>
              <span className="font-semibold">{dataUser?.username}</span>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
              size="lg"
            >
              <span>HỦY</span>
            </Button>
            <Button
              variant="gradient"
              color="green"
              onClick={handleClick}
              size="lg"
            >
              <span>ĐÃ THANH TOÁN</span>
            </Button>
          </DialogFooter>
        </Dialog>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <ToastContainer />
      </div>
    </>
  );
}

export default Recharge;
