import Navbar from "./Navbar";
import Header_xocdia from "./Header/header_xocdia";
import "./Layout.css";
import { useGetUsersInfoQuery } from "../../redux_toolkit/apiUser";
import React from "react";
import { useParams } from "react-router-dom";

function Layout_xocdia({ children }) {
  const { data: dataUserInfo } = useGetUsersInfoQuery();
  let { theloai } = useParams();
  document.title = "BET96TX - Xóc đĩa " + theloai + " phút";
  return (
    <div className="xocdia-layout">
      <Header_xocdia dataUserInfo={dataUserInfo} />
      <div className="content_xocdia">{children}</div>
      <Navbar />
    </div>
  );
}

export default Layout_xocdia;
