import { useState } from "react";
import Title from "../../components/common/tittle";
import Input from "../../components/common/input";
import Submit_btn from "../../components/common/submit_btn";
import NullData from "../../components/common/null_data";
import {
  useGetListUserBankQuery,
  useGetUsersInfoQuery,
  useChangeBankingMutation,
  useGetSettingQuery,
} from "../../redux_toolkit/apiUser";
import { checkTokenLogin } from "../../utils/function";
import React, { useEffect, useReducer } from "react";
import { toast } from "react-toastify";
import BankBox from "../../components/common/bank_box";
import { Link } from "react-router-dom";
import { RiBankLine } from "react-icons/ri";
import Select from "react-select";
import { IoAddCircleOutline } from "react-icons/io5";
import Loading from "../../components/common/loading";

function Bank() {
  const [editBank, setEditBank] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleEdit = () => {
    setEditBank(true);
  };
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
  } = useGetUsersInfoQuery();

  const {
    data: dataListBank,
    isLoading: isLoadingListBank,
    isFetching: isFetchingListBank,
  } = useGetListUserBankQuery();

  // console.log(dataListBank);

  // const [bank, dispatch] = useReducer(reducer, {
  //   nameuser: "",
  //   stk: "",
  //   nameBank: "",
  // });

  const [nameuser, setNameuser] = useState("");
  const [stk, setStk] = useState("");
  const [nameBank, setNameBank] = useState("");

  useEffect(() => {
    checkTokenLogin();
  }, []);

  useEffect(() => {
    if (!isLoadingListBank && !isLoadingUserInfo) {
      setLoading(false);
    }
  }, [isLoadingListBank, isLoadingUserInfo]);

  // if (!isLoadingListBank) {
  //   console.log(dataListBank?.data?.[0].title);
  // }

  // console.log(bank);

  const [changeBank, { data: res }] = useChangeBankingMutation();
  const handleSubmit = () => {
    // const { nameuser, stk, nameBank } = bank;
    if (!nameuser || !stk || !nameBank)
      return toast.warn("Nhập đầy đủ thông tin", { theme: "light" });
    changeBank({ nameuser, stk, nameBank });
  };
  useEffect(() => {
    if (res) {
      if (res.status === true) {
        window.location.href = "/addbank";
        toast.success(res.msg);
      } else if (res.status === false) {
        toast.error(res.msg);
      }
    }
  }, [res]);

  const handleChange = selectedOption => {
    setNameBank(selectedOption.value);
  };

  const handleUsername = event => {
    const value = event.target.value;
    setNameuser(value);
    if (value.length === 40) {
      return toast.error("Tên chủ tài khoản không được vượt quá 40 ký tự");
    }
  };
  const handleStk = event => {
    const value = event.target.value;
    setStk(value);
    if (value.length === 30) {
      return toast.error("Số tài khoản không được vượt quá 30 ký tự");
    }
  };
  const handleNamebank = event => {
    const value = event.target.value;
    setNameBank(value);
    if (value.length === 30) {
      return toast.error("Tên ngân hàng không được vượt quá 30 ký tự");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data, isLoading } = useGetSettingQuery();

  return (
    <div className="px-8">
      <Loading loading={loading} />
      <Title name="Liên kết ngân hàng" />
      {!editBank && dataUserInfo?.bank_name && (
        <>
          <div className="pt-[8rem]"></div>
          <BankBox
            bank={dataUserInfo.bank_name}
            name={dataUserInfo.bank_user}
            number={dataUserInfo.bank_number}
          />
        </>
      )}
      {!editBank && dataUserInfo?.bank_name2 && (
        <>
          <BankBox
            bank={dataUserInfo.bank_name2}
            name={dataUserInfo.bank_user2}
            number={dataUserInfo.bank_number2}
          />
        </>
      )}

      {!dataUserInfo?.bank_name2 && dataUserInfo?.bank_name && (
        <div
          className="flex items-center  p-4 w-fit rounded cursor-pointer text-[green] font-medium"
          onClick={handleEdit}
        >
          <IoAddCircleOutline />
          <span>Thêm ngân hàng</span>
        </div>
      )}

      {!editBank && dataUserInfo?.bank_name && (
        <div className="mt-8">
          <span className="italic text-[1.5rem] mr-2">
            Liên hệ chăm sóc khách hàng để thay đổi thông tin
          </span>
          <Link
            to={`https://secure.livechatinc.com/licence/${data?.data?.id_livechat}/v2/open_chat.cgi`}
            target="_blank"
            className="text-blue-500 font-bold"
          >
            CSKH
          </Link>
        </div>
      )}

      {!dataUserInfo?.bank_name && !editBank && (
        <div className="mt-24">
          <NullData name="Chưa liên kết tài khoản ngân hàng" />
          <Submit_btn
            text="Liên kết ngay"
            onClick={() => setEditBank(true)}
            width="100%"
          />
        </div>
      )}
      {editBank && dataListBank && (
        <div className="mt-24">
          <Input
            value={nameuser}
            placeholder="Chủ tài khoản"
            onChange={handleUsername}
            maxLength="40"
          />
          <Input
            value={stk}
            placeholder="Số tài khoản"
            onChange={handleStk}
            maxLength="30"
          />
          <div className="flex mt-4  w-[100%] p-2 rounded-2xl items-center">
            <RiBankLine className="mr-4 w-10 h-8" />
            <Input
              value={nameBank}
              placeholder="Tên ngân hàng"
              onChange={handleNamebank}
              maxLength="30"
            />
            {/* <Select
              onChange={handleChange}
              className="w-full"
              options={dataListBank.data.map((option) => ({
                value: option.title,
                label: option.title,
              }))}
              value={nameBank ? { value: nameBank, label: nameBank } : null}
              placeholder="Chọn ngân hàng"
            ></Select> */}
          </div>

          <Submit_btn text="Xác Nhận" onClick={handleSubmit} width="100%" />
        </div>
      )}
    </div>
  );
}

export default Bank;
