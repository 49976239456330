import PropTypes from "prop-types";

const Input = ({
  value = "",
  disabled = false,
  type = "text",
  name = "",
  style = {},
  onChange,
  placeholder = "",
  maxLength = "",
}) => {
  return (
    <input
      value={value}
      onChange={onChange}
      type={type}
      name={name}
      id={name}
      disabled={disabled}
      className="w-full border border-solid border-slate-300 rounded-3xl p-4 my-2 text-[1.8rem]"
      style={style}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  );
};

Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  type: PropTypes.string,
  value: PropTypes.string,
};

export default Input;
