import Navbar from "./Navbar";
import Header from "./Header";
import "./Layout.css";

function Layout({ children }) {
  // document.title = "BET96TX";
  return (
    <div className="wrapper">
      <Header link={children.props.link} title={children.props.title} />
      <div className="content">{children}</div>
      <Navbar link={children.props.link} title={children.props.title} />
    </div>
  );
}

export default Layout;
