import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function Dialog__HowplayKeno({ dialog, toggle }) {
  return (
    <>
      <Dialog open={dialog} handler={toggle} size="sm">
        <DialogHeader>
          <div className="flex flex-col">
            <span className="mr-4 text-[1.7rem]">Cách chơi Keno:</span>
            <span className="italic text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]">
              (Đổ 20 con số ngẫu nhiên và khác nhau trong khoảng từ 1 tới 80 và
              dự đoán kết quả!!!)
            </span>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="text-black flex flex-col text-[1.5rem] md:text-[1.3rem] sm:text-[1.2rem] overflow-y-scroll max-h-[50rem]">
            <div className="flex flex-col mb-4">
              <span className="font-bold">Cược gộp</span>
              <span>
                Tài: Tổng số của 20 quả bóng được rút ra lớn hơn hoặc bằng 811.
              </span>
              <span>
                Xỉu: Tổng số của 20 quả bóng được rút ra nhỏ hơn hoặc bằng 810.
              </span>
              <span>Lẻ: Tổng số của 20 quả bóng được rút ra là số lẻ.</span>
              <span>Chẵn: Tổng số của 20 quả bóng được rút ra là số chẵn.</span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="font-bold">Kèo đôi</span>
              <span>
                Tài Lẻ: Tổng 20 quả bóng được rút ra lớn hơn hoặc bằng 811 và là
                con số lẻ
              </span>
              <span>
                Xỉu Lẻ: Tổng 20 quả bóng được rút ra nhỏ hơn hoặc bằng 810 và là
                con số lẻ.
              </span>
              <span>
                Tài Chẵn: Tổng 20 quả bóng được rút ra lớn hơn hoặc bằng 811 và
                là con số chẵn.
              </span>
              <span>
                Xỉu Chẵn: Tổng 20 quả bóng được rút ra nhỏ hơn hoặc bằng 810 và
                là con số chẵn.
              </span>
            </div>

            <div className="flex flex-col mb-4">
              <span className="font-bold">Ngũ hành</span>
              <span>
                Kim: Tổng 20 quả bóng được rút ra trong khoản 210-695.
              </span>
              <span>
                Mộc: Tổng 20 quả bóng được rút ra trong khoản 696-763.
              </span>
              <span>
                Thủy: Tổng 20 quả bóng được rút ra trong khoản 764-855.
              </span>
              <span>
                Hỏa: Tổng 20 quả bóng được rút ra trong khoản 856-923.
              </span>
              <span>
                Thổ: Tổng 20 quả bóng được rút ra trong khoản 924- 1410.
              </span>
            </div>
            <div className="flex flex-col mb-4">
              <span className="font-bold">Trên dưới</span>
              <span>
                Trên: Nếu trong 20 quả bóng được rút ra có hơn 10 con số trong
                khoản từ 1-40.
              </span>
              <span>
                Hòa: Nếu trong 20 quả bóng được rút ra có 10 con số trong khoản
                1-40 và 10 con số trong khoản 41-80
              </span>
              <span>
                Dưới: Nếu trong 20 quả bóng được rút ra có hơn 10 con số trong
                khoản 41-80.
              </span>
            </div>
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default Dialog__HowplayKeno;
