import empty_data_img from "../../assets/images/empty_data.png";

function NullData({ name = "" }) {
  return (
    <div className="flex flex-col justify-center items-center">
      <img src={empty_data_img} className="w-[50%] mb-4" />
      <span className="italic text-[1.5rem]">{name}</span>
    </div>
  );
}

export default NullData;
