import { useEffect, useState } from "react";
import { useGetHistoryBetQuery } from "../../redux_toolkit/apiUser";
import {
  today,
  today_year_ago,
  format_money,
  formatTime,
  limitStringLength,
  convertAndFormatDateTime,
  isNumeric,
  checkTokenLogin,
  formattheloai,
  convertPhien,
} from "../../utils/function";

import NullData from "../../components/common/null_data";
import { BsClipboardData } from "react-icons/bs";
import xoso_components from "../../route/id_game";
import {
  list_xucxac,
  list_keno,
  historyplay_game,
  xo_so,
  Xocdia_Cuadat,
  list_keno5,
} from "../../utils/const";
import Loading from "../../components/common/loading";
import dayjs from "dayjs";
import { Table } from "antd";

function Historyplay() {
  const [game, setGame] = useState("Xổ số");
  const [type, setType] = useState(30);

  const [gameType, setGameType] = useState("keno5numbers");
  const [page, setPage] = useState(1);
  const { data, isLoading, isFetching } = useGetHistoryBetQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: gameType,
      providercode: "all",
      page: 1,
    },
    { refetchOnFocus: true }
  );
  useEffect(() => {
    checkTokenLogin();
  }, []);

  // console.log(data);

  const total_page = Math.ceil(data?.total / 50);
  const handleIncrement = () => {
    setPage(page + 1);
  };
  const handleDecrement = () => {
    setPage(page - 1);
  };
  const handleGame = (item) => {
    setGame(item.name);
    setGameType(item.code);
    setType(item.type);
  };

  const filteredArray = (str) => {
    return xoso_components.filter((item) => item.path.includes(str));
  };
  const handlePageChange = (number) => {
    setPage(number);
  };

  const columns = [
    {
      title: "Thời gian",
      dataIndex: "created_at",
      key: "created_at",
      render: (_) => (
        <div>
          <p>{dayjs(_).format("hh:mm:ss")}</p>
          <p>{dayjs(_).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Phiên",
      dataIndex: "phien",
      key: "phien",
      render: (phien) => <span>{convertPhien(phien)}</span>,
    },
    {
      title: "Số tiền",
      dataIndex: "money",
      key: "money",
      render: (money) => (
        <span className="whitespace-nowrap">{format_money(money)}</span>
      ),
    },
    {
      title: "Thắng",
      dataIndex: "win",
      key: "win",
      render: (win, record) =>
        !record.thanhtoan ? (
          <span className="text-blue-400 font-bold whitespace-nowrap">
            Chờ mở thưởng
          </span>
        ) : win > 0 ? (
          <span className="text-green-400 font-bold whitespace-nowrap">
            {format_money(win)}
          </span>
        ) : (
          <span className="text-red-400 font-bold whitespace-nowrap">0</span>
        ),
    },

    {
      title: "Dự án",
      dataIndex: "cuadat",
      key: "cuadat",
      render: (cuadat) => (
        <span className="font-bold text-blue-400">{list_keno5[cuadat]}</span>
      ),
    },
  ];

  const paginationConfig = {
    current: page,
    total: data?.total,
    pageSize: 50,
    onChange: handlePageChange,
    showSizeChanger: false,
  };

  return (
    <div className="px-4">
      <Loading loading={isLoading || isFetching} />
      <div className="text-white font-bold text-[3rem] py-10 relative z-50">
        Lịch sử tham gia
      </div>
      {/* <div className="flex justify-between my-16 flex-row flex-wrap bg-bluelightx2 p-4 rounded-2xl shadow border">
        {historyplay_game.map((item) => {
          return (
            <div
              className={
                game === item.name
                  ? "w-32 bg-bluelight border rounded-2xl text-white min-w-[7rem] flex-1 m-2 p-1"
                  : "w-32 bg-[#ddd] border rounded-2xl text-[#b5b5b5] min-w-[7rem] flex-1 m-2 p-1"
              }
              onClick={() => handleGame(item)}
            >
              {item.name}
            </div>
          );
        })}
      </div> */}
      {/* <div className="relative mt-[80px]">
        <div className="flex flex-col bg-bluelightx2 p-2 rounded shadow">
          <span className="sm:text-[1.2rem] md:text-[1.4rem] font-bold">
            {`Lịch sử tham gia ${
              data?.total === 0 ? "" : `Trang ${page} / ${total_page}`
            }`}
          </span>
          <span className="italic text-white font-thin text-[1.2rem] sm:text-[1rem]">{`Kết quả : ${
            data?.total === 0 ? "0 " : 50 * (page - 1) + 1
          }~${50 * page > data?.total ? data?.total : 50 * page}/Tổng số ${
            data?.total
          }`}</span>

          {page < total_page ? (
            <button
              className="absolute top-[50%] translate-y-[-50%] right-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-bluebold text-white p-2 rounded"
              onClick={handleIncrement}
            >
              Trang tiếp
            </button>
          ) : (
            ""
          )}

          {page > 1 ? (
            <button
              onClick={handleDecrement}
              className="absolute top-[50%] translate-y-[-50%] left-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-bluebold text-white p-2 rounded"
            >
              Trang trước
            </button>
          ) : (
            ""
          )}
        </div>
      </div> */}
      {!isLoading && gameType === "lottery" && (
        <>
          {!data ? (
            <>
              <NullData />
            </>
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 ? (
                <NullData />
              ) : (
                <div className="p-4 max-h-[50rem] overflow-y-auto">
                  {data.data.map((item) => {
                    return (
                      <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                        <div className="flex px-4 mb-2">
                          <span className="font-bold mr-4">{`Thể loại: ${
                            xo_so[item.type]
                          }`}</span>
                          {item.thanhtoan === 0 ? (
                            <span className="bg-blue-400 rounded-lg px-2 text-white">
                              Chờ mở thưởng
                            </span>
                          ) : item.win > 0 ? (
                            <span className="bg-green-400 rounded-lg px-2 text-white">
                              Thắng cược
                            </span>
                          ) : (
                            <span className="bg-[red] rounded-lg px-2 text-white">
                              Thất bại
                            </span>
                          )}
                        </div>
                        <div className="flex  px-4 justify-between">
                          <span className=" text-blue-400 font-bold text-left">{`Cược: ${format_money(
                            item.cuoc
                          )} `}</span>
                          {item.thanhtoan === 1 && item.win > 0 && (
                            <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                              item.win
                            )} `}</span>
                          )}
                        </div>
                        <div className="flex px-4 justify-between">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                          {isNumeric(item.theloai) ? (
                            <div>
                              <span className="mr-2">Loại:</span>
                              <span className="font-bold">
                                {formatTime(item.theloai)}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <span className="mr-2">Loại:</span>
                              <span className="font-bold">
                                {filteredArray(item.theloai)[0].menu_name}
                              </span>
                            </div>
                          )}
                        </div>

                        <div className="flex px-4 overflow-hidden">{`Số: ${limitStringLength(
                          item?.so,
                          40
                        )} `}</div>
                        <div className="flex px-4">
                          {`Thời gian cược: ${convertAndFormatDateTime(
                            item.created_at
                          )}`}
                        </div>
                        {item.thanhtoan === 1 && (
                          <div className="flex px-4">
                            {`Mở thưởng lúc: ${convertAndFormatDateTime(
                              item.updated_at
                            )}`}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          {data?.length !== 0 && page === total_page && (
            <div className="w-[100%] my-[2rem] flex justify-center items-center">
              <BsClipboardData className="w-8 h-8" />
              <span className="italic ">Chưa có thêm dữ liệu!</span>
            </div>
          )}
          {data?.total === 0 && (
            <>
              <div className="pt-[8rem]"></div>
              <NullData name="Chưa có dữ liệu" />
            </>
          )}
        </>
      )}
      {!isLoading && gameType === "xocdia" && (
        <>
          {!data ? (
            <NullData />
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 && <NullData name="Chưa có dữ liệu cược" />}
              <div className="p-4 max-h-[50rem] overflow-y-auto">
                {data.data.map((item) => {
                  return (
                    <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                      <div className="flex px-4 mb-2">
                        <span className="font-bold mr-4">{`Thể loại: ${item.theloai}`}</span>
                        {item.thanhtoan === 0 ? (
                          <span className="bg-blue-400 rounded-lg px-2 text-white">
                            Chờ mở thưởng
                          </span>
                        ) : (
                          <span className="bg-green-400 rounded-lg px-2 text-white">
                            Đã mở thưởng
                          </span>
                        )}
                      </div>
                      <div className="flex  px-4 justify-between">
                        <span className=" text-[red] font-bold text-left">{`Cược: ${format_money(
                          item.cuoc
                        )} `}</span>
                        {item.thanhtoan === 1 ? (
                          <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                            item.win
                          )} `}</span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="flex justify-between">
                        <div className="flex px-4 justify-between">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                        </div>

                        <div className="flex px-4">
                          <span>Cửa đặt:</span>
                          <span className="font-bold text-[red]">
                            {Xocdia_Cuadat[item.cuadat]}
                          </span>
                        </div>
                      </div>
                      <div className="flex px-4">
                        {`Thời gian cược: ${convertAndFormatDateTime(
                          item.created_at
                        )}`}
                      </div>
                      {item.thanhtoan === 1 && (
                        <div className="flex px-4">
                          {`Mở thưởng lúc: ${convertAndFormatDateTime(
                            item.updated_at
                          )}`}
                        </div>
                      )}
                    </div>
                  );
                })}
                {data.data.length !== 0 && page === total_page && (
                  <div className="w-[100%] my-[2rem] flex justify-center items-center">
                    <BsClipboardData className="w-8 h-8" />
                    <span className="italic ">Chưa có thêm dữ liệu!</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!isLoading && gameType === "sicbo" && (
        <>
          {!data ? (
            <NullData />
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative">
              {data.total === 0 && <NullData name="Chưa có dữ liệu cược" />}
              <div className="p-4 max-h-[50rem] overflow-y-auto">
                {data.data.map((item) => {
                  return (
                    <div className="p-4 rounded bg-white shadow mb-4 md:text-[1.3rem] sm:text-[1.2rem]">
                      <div className="flex px-4 mb-2">
                        <span className="font-bold mr-4">{`Thể loại: ${formattheloai(
                          item.theloai
                        )}`}</span>
                        {item.thanhtoan === 0 ? (
                          <span className="bg-blue-400 rounded-lg px-2 text-white">
                            Chờ mở thưởng
                          </span>
                        ) : item.win > 0 ? (
                          <span className="bg-green-400 rounded-lg px-2 text-white">
                            Thắng cược
                          </span>
                        ) : (
                          <span className="bg-[red] rounded-lg px-2 text-white">
                            Thất bại
                          </span>
                        )}
                      </div>
                      <div className="flex  px-4 justify-between">
                        <span className=" text-blue-400 font-bold text-left">{`Cược: ${format_money(
                          item.money
                        )} `}</span>
                        {item.thanhtoan === 1 && item.win > 0 && (
                          <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                            item.win
                          )} `}</span>
                        )}
                      </div>
                      <div className="flex justify-between md:flex-col sm:flex-col">
                        <div className="flex px-4 text-left">
                          <span>{`Phiên: ${convertPhien(item.phien)} `}</span>
                        </div>

                        <div className="flex px-4">
                          <span className="mr-2">Cửa đặt:</span>
                          <span className="font-bold text-blue-400 text- text-left">
                            {`${list_xucxac[item.cuadat]}`}
                          </span>
                        </div>
                      </div>
                      <div className="flex px-4">
                        {`Thời gian cược: ${convertAndFormatDateTime(
                          item.created_at
                        )}`}
                      </div>
                      {item.thanhtoan === 1 && (
                        <div className="flex px-4">
                          {`Mở thưởng lúc: ${convertAndFormatDateTime(
                            item.updated_at
                          )}`}
                        </div>
                      )}
                    </div>
                  );
                })}
                {data.data.length !== 0 && page === total_page && (
                  <div className="w-[100%] my-[2rem] flex justify-center items-center">
                    <BsClipboardData className="w-8 h-8" />
                    <span className="italic ">Chưa có thêm dữ liệu!</span>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!isLoading && gameType === "keno5numbers" && (
        <>
          <div
            className="overflow-x-auto scrollbar-hide mt-[80px]" // Ẩn thanh cuộn nhưng vẫn cho phép cuộn
          >
            <Table
              dataSource={data?.data}
              columns={columns}
              pagination={paginationConfig}
              scroll={{ x: "max-content" }} // Scroll theo chiều ngang
              rowKey="id" // Assuming each item has a unique 'id'
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Historyplay;
