import phuyen from "../assets/images/phuyen.png";
import thuathienhue from "../assets/images/thuathienhue.png";
import daklak from "../assets/images/daklak.png";
import quangnam from "../assets/images/quangnam.png";
import danang from "../assets/images/danang.png";
import khanhhoa from "../assets/images/khanhhoa.png";
import binhdinh from "../assets/images/binhdinh.png";
import quangbinh from "../assets/images/quangbinh.png";
import quangtri from "../assets/images/quangtri.png";
import gialai from "../assets/images/gialai.png";
import ninhthuan from "../assets/images/ninhthuan.png";
import daknong from "../assets/images/daknong.png";
import quangngai from "../assets/images/quangngai.png";
import kontum from "../assets/images/kontum.png";
import dalat from "../assets/images/dalat.png";
import kiengiang from "../assets/images/kiengiang.png";
import tiengiang from "../assets/images/tiengiang.png";
import camau from "../assets/images/camau.png";
import dongthap from "../assets/images/dongthap.png";
import tphcm from "../assets/images/tphcm.png";
import baclieu from "../assets/images/baclieu.png";
import vungtau from "../assets/images/vungtau.png";
import cantho from "../assets/images/cantho.png";
import dongnai from "../assets/images/dongnai.png";
import soctrang from "../assets/images/soctrang.png";
import angiang from "../assets/images/angiang.png";
import binhthuan from "../assets/images/binhthuan.png";
import tayninh from "../assets/images/tayninh.png";
import binhduong from "../assets/images/binhduong.png";
import travinh from "../assets/images/travinh.png";
import binhphuoc from "../assets/images/binhphuoc.png";
import haugiang from "../assets/images/haugiang.png";
import longan from "../assets/images/longan.png";

const xoso_components = [
  {
    menu_id: 1,
    menu_name: "Miền Bắc",
    path: "/xsmb",
    time: "18:40",
    days: [0, 1, 2, 3, 4, 5, 6],
  },
  {
    menu_id: 2,
    menu_name: "Miền Trung Phú Yên",
    path: "/xsmt-xspy",
    time: "17:40",
    days: [1],
    img: phuyen,
  },
  {
    menu_id: 3,
    menu_name: "Miền Trung Thừa T. Huế",
    path: "/xsmt-xstth",
    time: "17:40",
    days: [0, 1],
    img: thuathienhue,
  },
  {
    menu_id: 4,
    menu_name: "Miền Trung Đắk Lắk",
    path: "/xsmt-xsdlk",
    time: "17:40",

    days: [2],
    img: daklak,
  },
  {
    menu_id: 5,
    menu_name: "Miền Trung Quảng Nam",
    path: "/xsmt-xsqnm-xsqna",
    time: "17:40",

    days: [2],
    img: quangnam,
  },
  {
    menu_id: 6,
    menu_name: "Miền Trung Đà Nẵng",
    path: "/xsmt-xsdng-xsdna",
    time: "17:40",

    days: [3, 6],
    img: danang,
  },
  {
    menu_id: 7,
    menu_name: "Miền Trung Khánh Hòa",
    path: "/xsmt-xskh",
    time: "17:40",

    days: [0, 3],
    img: khanhhoa,
  },
  {
    menu_id: 8,
    menu_name: "Miền Trung Bình Định",
    path: "/xsmt-xsbdi",
    time: "17:40",

    days: [4],
    img: binhdinh,
  },
  {
    menu_id: 9,
    menu_name: "Miền Trung Quảng Bình",
    path: "/xsmt-xsqb",
    time: "17:40",

    days: [4],
    img: quangbinh,
  },
  {
    menu_id: 10,
    menu_name: "Miền Trung Quảng Trị",
    path: "/xsmt-xsqt",
    time: "17:40",

    days: [4],
    img: quangtri,
  },
  {
    menu_id: 11,
    menu_name: "Miền Trung Gia Lai",
    path: "/xsmt-xsgl",
    time: "17:40",

    days: [5],
    img: gialai,
  },
  {
    menu_id: 12,
    menu_name: "Miền Trung Ninh Thuận",
    path: "/xsmt-xsnt",
    time: "17:40",

    days: [5],
    img: ninhthuan,
  },
  {
    menu_id: 13,
    menu_name: "Miền Trung Đắk Nông",
    path: "/xsmt-xsdno",
    time: "17:40",

    days: [6],
    img: daknong,
  },
  {
    menu_id: 14,
    menu_name: "Miền Trung Quảng Ngãi",
    path: "/xsmt-xsqng",
    time: "17:40",

    days: [6],
    img: quangngai,
  },
  {
    menu_id: 15,
    menu_name: "Miền Trung Kon Tum",
    path: "/xsmt-xskt",
    time: "17:40",

    days: [0],
    img: kontum,
  },
  {
    menu_id: 16,
    menu_name: "Miền Nam Đà Lạt",
    path: "/xsmn-xsld-xsdl",
    time: "16:40",
    days: [0],
    img: dalat,
  },
  {
    menu_id: 17,
    menu_name: "Miền Nam Kiên Giang",
    path: "/xsmn-xskg",
    time: "16:40",

    days: [0],
    img: kiengiang,
  },
  {
    menu_id: 18,
    menu_name: "Miền Nam Tiền Giang",
    path: "/xsmn-xstg",
    time: "16:40",

    days: [0],
    img: tiengiang,
  },
  {
    menu_id: 19,
    menu_name: "Miền Nam Cà Mau",
    path: "/xsmn-xscm",
    time: "16:40",

    days: [1],
    img: camau,
  },
  {
    menu_id: 20,
    menu_name: "Miền Nam Đồng Tháp",
    path: "/xsmn-xsdt",
    time: "16:40",

    days: [1],
    img: dongthap,
  },
  {
    menu_id: 21,
    menu_name: "Miền Nam TP. Hồ Chí Minh",
    path: "/xsmn-xshcm-xstp",
    time: "16:40",

    days: [1, 6],
    img: tphcm,
  },
  {
    menu_id: 22,
    menu_name: "Miền Nam Bạc Liêu",
    path: "/xsmn-xsbl",
    time: "16:40",

    days: [2],
    img: baclieu,
  },
  {
    menu_id: 23,
    menu_name: "Miền Nam Vũng Tàu",
    path: "/xsmn-xsvt",
    time: "16:40",

    days: [2],
    img: vungtau,
  },
  {
    menu_id: 24,
    menu_name: "Miền Nam Cần Thơ",
    path: "/xsmn-xsct",
    time: "16:40",

    days: [3],
    img: cantho,
  },
  {
    menu_id: 25,
    menu_name: "Miền Nam Đồng Nai",
    path: "/xsmn-xsdn",
    time: "16:40",

    days: [3],
    img: dongnai,
  },
  {
    menu_id: 26,
    menu_name: "Miền Nam Sóc Trăng",
    path: "/xsmn-xsst",
    time: "16:40",

    days: [3],
    img: soctrang,
  },
  {
    menu_id: 27,
    menu_name: "Miền Nam An Giang",
    path: "/xsmn-xsag",
    time: "16:40",

    days: [4],
    img: angiang,
  },
  {
    menu_id: 28,
    menu_name: "Miền Nam Bình Thuận",
    path: "/xsmn-xsbth",
    time: "16:40",

    days: [4],
    img: binhthuan,
  },
  {
    menu_id: 29,
    menu_name: "Miền Nam Tây Ninh",
    path: "/xsmn-xstn",
    time: "16:40",

    days: [4],
    img: tayninh,
  },
  {
    menu_id: 30,
    menu_name: "Miền Nam Bình Dương",
    path: "/xsmn-xsbd",
    time: "16:40",

    days: [5],
    img: binhduong,
  },
  {
    menu_id: 31,
    menu_name: "Miền Nam Trà Vinh",
    path: "/xsmn-xstv",
    time: "16:40",

    days: [5],
    img: travinh,
  },
  {
    menu_id: 32,
    menu_name: "Miền Nam Bình Phước",
    path: "/xsmn-xsbp",
    time: "16:40",

    days: [6],
    img: binhphuoc,
  },
  {
    menu_id: 33,
    menu_name: "Miền Nam Hậu Giang",
    path: "/xsmn-xshg",
    time: "16:40",

    days: [6],
    img: haugiang,
  },
  {
    menu_id: 34,
    menu_name: "Miền Nam Vĩnh Long",
    path: "/xsmn-xsvl",
    time: "16:40",
    days: [5],
    img: longan,
  },
];

export default xoso_components;
