import { useGetRechargeHistoryQuery } from "../../redux_toolkit/apiUser";
import Title from "../../components/common/tittle";
import dayjs from "dayjs";

import NullData from "../../components/common/null_data";
import { useState, useEffect } from "react";
import {
  formatTime,
  format_money,
  checkTokenLogin,
} from "../../utils/function";
import Loading from "../../components/common/loading";
// import { useGetUsersInfoQuery } from "../../redux_toolkit/apiUser";

function Recharge_history() {
  const [page, setPage] = useState(1);
  const username = localStorage.getItem("username");
  const { data, isLoading, refetch } = useGetRechargeHistoryQuery({
    username,
    page,
  });

  console.log(data);

  useEffect(() => {
    checkTokenLogin();
    refetch();
  }, []);
  return (
    <>
      <Loading loading={isLoading} />
      <Title name="Lịch sử nạp" />
      <div className="mt-28"></div>
      {!isLoading && (
        <>
          {data.data.rows.length === 0 ? (
            <NullData />
          ) : (
            <div className="flex relative z-50 w-full text-[1.7rem] md:text-[1.5rem] sm:text-[1.4rem]">
              <div className="bg-[aliceblue] rounded shadow  relative w-full">
                <div className="p-4 max-h-[60vh] overflow-y-auto">
                  {data?.data.rows.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="bg-white border border-solid border-slate-400 rounded-2xl p-4 my-3"
                      >
                        <div className="flex justify-between mb-4 items-center">
                          <div>
                            <span className="font-bold mr-4">NẠP</span>
                            {item.status === 0 ? (
                              <span className="bg-blue-400 rounded-lg p-2 text-white">
                                Chờ xử lý
                              </span>
                            ) : item.status === 1 ? (
                              <span className="bg-green-400 rounded-lg p-2 text-white">
                                Thành công
                              </span>
                            ) : (
                              <span className="bg-[red] rounded-lg p-2 text-white">
                                Thất bại
                              </span>
                            )}
                          </div>
                          <p className="font-bold mr-2">
                            <span
                              className={
                                //   item.status === 0
                                "text-blue-400"
                                //     : item.status === 1
                                //     ? "text-green-400 "
                                //     : "text-[red]"
                              }
                            >
                              {format_money(item.amount)}
                            </span>
                          </p>
                        </div>
                        {item.status !== 0 && (
                          <div className="text-left">
                            {item.description
                              ? `Mô tả: ${item.description}`
                              : `Mô tả: Không có`}
                          </div>
                        )}
                        {item.status === 0 && (
                          <div className="text-left">{`Tạo lệnh: ${dayjs(
                            item.created_at
                          ).format("HH:mm DD-MM-YYYY")} `}</div>
                        )}

                        {item.status !== 0 && (
                          <div className="text-left">{`Cập nhật: ${dayjs(
                            item.updated_at
                          ).format("HH:mm DD-MM-YYYY")} `}</div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default Recharge_history;
