import Countdown2 from "../../../../components/cowndown/cowndown2";

function Heading_taixiu({ message, time, phien }) {
  // console.log(message);
  return (
    <div className="flex flex-col justify-center items-center py-[3rem] rounded-3xl relative z-10 bg-[aliceblue] shadow">
      <div className="flex py-4 flex-col items-center justify-center w-full">
        <div className="mb-4">
          <span className="text-gray-400 text-3xl mr-2">Phiên số:</span>
          <span className="text-3xl py-4">{phien}</span>
        </div>
      </div>
      <Countdown2 time={time} />
    </div>
  );
}

export default Heading_taixiu;
