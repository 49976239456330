import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import "./modal.css";
import {
  limitStringLength,
  format_money_no_monetary_unit,
} from "../../utils/function";

import {
  list_xucxac,
  list_keno,
  Xocdia_Cuadat,
  list_keno5,
} from "../../utils/const";

function Dialog_confirm_info_bet({
  dialog,
  toggle1,
  toggle2,
  tab,
  point,
  numbers,
  count,
  cuadat,
  cuadatKeno,
  cuadatXocdia,
}) {
  // console.log(numbers.join());
  return (
    <>
      <Dialog
        open={dialog}
        handler={toggle2}
        size="sm"
        className="modal__confirm-bet"
      >
        <DialogHeader>
          <span className="m-auto">Xác nhận đặt cược</span>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col pl-10 text-blue-600 w-[80%] m-auto text-[1.5rem]">
            {tab && <span>{`Thể loại: ${tab}`}</span>}
            {numbers && (
              <span>{`Số chọn: ${limitStringLength(
                numbers?.join(),
                30
              )}`}</span>
            )}

            <span>{`Điểm cược: ${format_money_no_monetary_unit(point)}`}</span>
            {count && <span>{`Số đã chọn: ${count}`}</span>}
            {cuadat && (
              <span>{`Cửa đặt: ${cuadat.map(
                (item) => `${list_xucxac[item]} `
              )}`}</span>
            )}
            {cuadatKeno && (
              <span>{`Cửa đặt: ${cuadatKeno.map(
                (item) => `${list_keno5[item]} `
              )}`}</span>
            )}
            {cuadatXocdia && (
              <span>{`Cửa đặt: ${cuadatXocdia.map(
                (item) => `${Xocdia_Cuadat[item]} `
              )}`}</span>
            )}
          </div>
          <div className="flex items-center justify-center ">
            <div
              className="rounded-full h-[4rem] w-[10rem] leading-[4rem] bg-gradient-to-b from-green-300 to-green-600 text-center  cursor-pointer text-white text-[2rem] font-bold"
              color="secondary"
              onClick={toggle1}
            >
              Cược
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button color="secondary" onClick={toggle2}>
            Đóng
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default Dialog_confirm_info_bet;
