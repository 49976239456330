function Positive_negative_money({ money }) {
  if (money > 0) {
    return (
      <div className="bg-[green] rounded-lg p-2 text-white font-bold text-[1.5rem]">
        {"+" + new Intl.NumberFormat().format(money)}
      </div>
    );
  } else {
    return (
      <div className="bg-[red] rounded-lg p-2 text-white font-bold text-[1.5rem]">
        {new Intl.NumberFormat().format(money)}
      </div>
    );
  }
}

export default Positive_negative_money;
