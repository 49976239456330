import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function Dialog_xoso_result_detail({ data, g1_7, dd, dialog, toggle }) {
  return (
    <>
      <Dialog open={dialog} handler={toggle} size="xs">
        <DialogBody>
          <table className="custom-table text-[1.5rem]">
            <tr>
              <td className="w-[10%]">GDB</td>
              <td className="w-[90%] text-red-500 text-3xl font-bold">
                {data.gdb}
              </td>
            </tr>

            <tbody>
              {g1_7.map((item, index) => {
                return (
                  <>
                    {
                      <tr>
                        <td className="w-[10%]">{index + 1}</td>
                        <td className="flex-row flex-wrap w-[90%] font-bold">
                          {item.split(",").map((number) => {
                            return (
                              <span
                                className={
                                  item.length === 19 || item.length === 11
                                    ? "p-2 w-[25%]"
                                    : "p-2 w-[33%]"
                                }
                              >
                                {number}
                              </span>
                            );
                          })}
                        </td>
                      </tr>
                    }
                  </>
                );
              })}
            </tbody>
          </table>

          {/* <div className="flex mt-4 text-[1.4rem]">
            <table className="tb-dd ">
              <tbody>
                <tr>
                  <th>Đầu</th>
                  <th>Đuôi</th>
                </tr>
                {dd.slice(0, 5).map((item, index) => {
                  return (
                    <tr>
                      <td className="font-bold">{index}</td>
                      <td>{item}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <table className="tb-dd">
              <tbody>
                <tr>
                  <th>Đầu</th>
                  <th>Đuôi</th>
                </tr>
                {dd.slice(-5).map((item, index) => {
                  return (
                    <tr>
                      <td className="font-bold">{index + 5}</td>
                      <td>{item}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div> */}
        </DialogBody>
        <DialogFooter>
          <Button color="secondary" onClick={toggle}>
            Đóng
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default Dialog_xoso_result_detail;
