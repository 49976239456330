import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function Dialog__Howplay({ dialog, toggle, text, tab1, tab2 }) {
  return (
    <>
      <Dialog open={dialog} handler={toggle} size="sm">
        <DialogHeader>
          <>
            <span className="mr-4">Cách chơi:</span>
            <span>{`${tab2}(${tab1})`}</span>
          </>
        </DialogHeader>
        <DialogBody>
          <span className="text-[1.2rem]">{text}</span>
        </DialogBody>
      </Dialog>
    </>
  );
}

export default Dialog__Howplay;
