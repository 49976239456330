import Taixiu_bet from "./components/kenobet";
import Table_taixiu from "../../../components/table_result/table_taixiu";
import { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import {
  useGetHistoryBetQuery,
  useGetUsersInfoQuery,
  useGetResultsHistoryQuery,
} from "../../../redux_toolkit/apiUser";
import { today, today_year_ago } from "../../../utils/function";
import Heading_xucxac from "./components/heading";
import { HistoryBet } from "./components/historybet";
import { useReducer } from "react";
import Dialog__NoticeWin from "../../../components/modal/Dialog_notice_win";
import NullData from "../../../components/common/null_data";
import Keno_bet from "./components/kenobet";
import Table_keno from "../../../components/table_result/table_keno";
import Heading_keno from "./components/heading";
import { useParams } from "react-router-dom";
import Loading from "../../../components/common/loading";
import Kenospin from "../../../components/spin/keno_spin/keno_spin";
import { toast } from "react-toastify";
import config from "../../../config";
const reducer = (state, { action, payload }) => {
  switch (action) {
    case "page":
      return { ...state, page: payload };
    default:
      return state;
  }
};
const socketUrl = config.REACT_APP_WEBSOCKET;
// const socketUrl = process.env.REACT_APP_WEBSOCKET;

function Keno(args) {
  const { type } = useParams();
  // console.log(type);
  // console.log(type);
  const [history, setHistory] = useState(0);
  const [message, setMessage] = useState();
  const [modalWin, setModalWin] = useState(false);
  const [dataModalWin, setDataModalWin] = useState({});
  const [time, setTime] = useState(0);
  const [phien, setPhien] = useState("");
  const [created, setCreated] = useState("");
  const [loading, setLoading] = useState(true);
  const [tile, setTile] = useState({});
  const [state, dispatch] = useReducer(reducer, {
    page: 1,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  // console.log(message);

  useEffect(() => {
    sendJsonMessage({ auth: { token: localStorage.getItem("auth") } });
    sendJsonMessage({ keno5numbers: { getTiLe: true } });
  }, []);
  // console.log(phien);
  useEffect(() => {
    // console.log(lastMessage);
    if (lastMessage !== null) {
      const a = JSON.parse(lastMessage.data);

      // console.log(a);
      if (a?.keno5numbers?.noticeData || a?.notice) {
        setMessage(a);
      } else {
        if (a?.keno5numbers?.info?.theloai == type) {
          setLoading(false);
          setMessage(a.keno5numbers.info);
          setPhien(a.keno5numbers.info.phien);
          setCreated(a.keno5numbers.info.created_at);
          if (a.keno5numbers.info.time > 0) {
            setTime(a.keno5numbers.info.time);
          } else {
            setTime(0);
          }
        }
        if (a?.keno5numbers?.tile) {
          setTile(a?.keno5numbers?.tile);
        }
      }
    }
  }, [lastMessage]);

  // console.log(message);

  const handleClickHistory = (number) => {
    setHistory(number);
  };
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  // console.log(dataUserInfo);

  const {
    data: dataResultsHistory,
    isLoading: isLoadingResultsHistory,
    isFetching: isFetchingResultHitory,
    refetch: refetchResultsHistory,
  } = useGetResultsHistoryQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "keno5numbers",
      page: 1,
    },
    { refetchOnFocus: true }
  );
  // console.log(dataResultsHistory);
  const {
    data: dataHistoryBet,
    isLoading: isLoadingHistoryBet,
    isFetching: isFetchingHistoryBet,
    refetch: refetchHistoryBet,
  } = useGetHistoryBetQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "keno5numbers",
      providercode: "all",
      page: state.page,
    },
    { refetchOnFocus: true }
  );
  useEffect(() => {
    if (message?.keno5numbers?.noticeData) {
      refetchUserInfo();
      const a = Object.values(message?.keno5numbers?.noticeData)[0];
      const data = Object.values(a)[0];
      setModalWin(true);
      setTimeout(() => {
        setModalWin(false);
      }, 3000);
      setDataModalWin(data);
      console.log(data);
    }
  }, [message?.keno5numbers?.noticeData]);

  useEffect(() => {
    if (message?.notice) {
      refetchUserInfo();
      refetchHistoryBet();
    }
  }, [message?.notice]);

  useEffect(() => {
    refetchResultsHistory();
    refetchHistoryBet();
  }, [phien]);

  return (
    <>
      <Loading
        loading={
          loading ||
          isLoadingHistoryBet ||
          isFetchingUserInfo ||
          isLoadingResultsHistory
        }
      />
      {/* <Loading
        loading={
          isLoadingHistoryBet && isLoadingUserInfo && isLoadingResultsHistory
        }
      /> */}
      <Heading_keno message={message} type={type} time={time} phien={phien} />
      <Kenospin message={message} time={time} type={type} />
      <Keno_bet
        sendJsonMessage={sendJsonMessage}
        message={message}
        dataUser={dataUserInfo}
        type={type}
        time={time}
        phien={phien}
        tile={tile}
      />
      <div>
        <div className="flex my-6 flex-row flex-wrap">
          <div
            className={
              history === 0
                ? "history-btn-xx active md:text-[1.5rem] sm:text-[1.4rem]"
                : "history-btn-xx md:text-[1.5rem] sm:text-[1.4rem] font-thin"
            }
            onClick={() => handleClickHistory(0)}
          >
            Dự án
          </div>
          <div
            className={
              history === 1
                ? "history-btn-xx active md:text-[1.5rem] sm:text-[1.4rem]"
                : "history-btn-xx md:text-[1.5rem] sm:text-[1.4rem] font-thin"
            }
            onClick={() => handleClickHistory(1)}
          >
            Lịch sử của tôi
          </div>
          {/* <div
            className={
              history === 2
                ? "history-btn-xx active md:text-[1.4rem] sm:text-[1.3rem]"
                : "history-btn-xx md:text-[1.4rem] sm:text-[1.3rem]"
            }
            onClick={() => handleClickHistory(2)}
          >
            Ẩn lịch sử
          </div> */}
        </div>
        {history === 0 && (
          <Table_keno
            data={dataResultsHistory}
            phien={phien}
            created={created}
          />
        )}
        {history === 1 && (
          <HistoryBet data={dataHistoryBet} state={state} dispatch={dispatch} />
        )}
        {history === 2 && <NullData name="Đã ẩn lịch sử" />}
      </div>
      <Dialog__NoticeWin
        data={dataModalWin}
        dialog={modalWin}
        toggle={() => setModalWin(!modalWin)}
        isKeno={true}
      />
    </>
  );
}

export default Keno;
