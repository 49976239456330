import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkTokenApi } from "../utils/function";
import config from "../config";

const domain = config.REACT_APP_DOMAIN;

// const domain = process.env.REACT_APP_DOMAIN;

export const apiAgency = createApi({
  reducerPath: "apiAgency",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/daily" }),

  endpoints: builder => ({
    getSettingAgency: builder.query({
      query: () => ({
        url: `/check`,
        headers: headers(),
        method: "GET",
      }),
      transformResponse: res => {
        checkTokenApi(res);
        return res;
      },
    }),
  }),
});

export const { useGetSettingAgencyQuery } = apiAgency;
