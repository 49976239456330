import { memo, useEffect, useState } from "react";
import vietllot_logo from "../../../assets/images/vietlot_logo.png";
import {
  useGetSettingQuery,
  useGetUsersInfoQuery,
} from "../../../redux_toolkit/apiUser";
import { format_money } from "../../../utils/function";
// import React, { useEffect } from "react";
// import { useEffect } from "react";
import useWebSocket from "react-use-websocket";
import Custom_btn from "../../common/custom_btn";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { ImEyeBlocked } from "react-icons/im";
import { ImEye } from "react-icons/im";
import config from "../../../config";

const domain = config.REACT_APP_DOMAIN;
const socketUrl = config.REACT_APP_WEBSOCKET;
// const socketUrl = process.env.REACT_APP_WEBSOCKET;
// const domain = process.env.REACT_APP_DOMAIN;
function Header() {
  const [hide, setHide] = useState(false);
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  const [message, setMessage] = useState();
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl);

  useEffect(() => {
    if (lastMessage !== null) {
      // console.log(message);
      setMessage(JSON.parse(lastMessage.data));
    }
  }, [lastMessage]);

  useEffect(() => {
    refetchUserInfo();
  }, [message?.notice?.msg]);

  const { data, isLoading } = useGetSettingQuery();
  // console.log(dataUserInfo);
  return (
    <div className="header-wrapper">
      <div className="flex h-12 items-center justify-between mx-6 relative z-[3]">
        <img src={domain + data?.data?.logo_image} className="h-[55px]"></img>
        <div className="flex w-[210px] items-center justify-end">
          {localStorage.getItem("auth") ? (
            <>
              {!isLoadingUserInfo && (
                <div className="header-text font-bold flex">
                  <span className="header-text mr-2">Số dư:</span>
                  <div>
                    {hide ? (
                      <div className="flex items-center">
                        <span>******</span>
                        <ImEye
                          className="ml-2"
                          size={17}
                          onClick={() => setHide(false)}
                        />
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{format_money(dataUserInfo?.money ?? 0)}</span>
                        <ImEyeBlocked
                          className="ml-2"
                          size={17}
                          onClick={() => setHide(true)}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <Link to="/login">
                <Button
                  variant="gradient"
                  size="lg"
                  color="deep-purple"
                  ripple={true}
                  className="lg:text-xl xs:text-sm mr-4"
                >
                  Đăng nhập
                </Button>
              </Link>
              <Link to="/register">
                <Button
                  variant="gradient"
                  size="lg"
                  color="light-blue"
                  ripple={true}
                  className="lg:text-xl xs:text-sm"
                >
                  Đăng ký
                </Button>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default memo(Header);
