import "./table_result.css";
import xxh1 from "../../assets/images/xx_history1.png";
import xxh2 from "../../assets/images/xx_history2.png";
import xxh3 from "../../assets/images/xx_history3.png";
import xxh4 from "../../assets/images/xx_history4.png";
import xxh5 from "../../assets/images/xx_history5.png";
import xxh6 from "../../assets/images/xx_history6.png";
import "../../pages/game/game.css";
import { convertPhien, format_time_table_taixiu } from "../../utils/function";
// import NullData from "../common/null_data";
import dayjs from "dayjs";

const xx_history = [xxh1, xxh2, xxh3, xxh4, xxh5, xxh6];

const Table_xucxac = ({ data, phien, created }) => {
  const sum_char = (number) => {
    const digits = number.split(",").map(Number);
    const sum = digits.reduce((acc, digit) => acc + digit, 0);
    return sum;
  };
  return (
    <div className="wrap-table-taixiu">
      <table className="table-taixiu text-[1.4rem] md:text-[1.3rem] sm:text-[1.2rem] relative">
        <thead>
          <tr className="bg-bluelight py-4">
            <th className="w-[30%]">Phiên số</th>
            <th className="w-[40%]">Kết quả</th>

            <th className="w-[30%]">Thời gian</th>
          </tr>
        </thead>
        <tbody>
          <tr className="py-4">
            <td className="w-[30%]">{convertPhien(phien)}</td>

            <td className="w-[40%]">Đang chờ kết quả</td>

            <td className="w-[30%]">
              {dayjs(created).format("YYYY/MM/DD HH:mm")}
            </td>
          </tr>
          {data?.map((item, index) => (
            <tr key={index} className="py-4">
              <td className="w-[30%]">{convertPhien(item.phien)}</td>
              <td className="flex justify-center">
                <img src={xx_history[item.ketqua.split(",")[0] - 1]} />
                <img src={xx_history[item.ketqua.split(",")[1] - 1]} />
                <img src={xx_history[item.ketqua.split(",")[2] - 1]} />
              </td>
              <td className="w-[30%]">
                {dayjs(item?.updated_at).format("YYYY/MM/DD HH:mm")}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table_xucxac;
