import "./table_result.css";
import xxh1 from "../../assets/images/xx_history1.png";
import xxh2 from "../../assets/images/xx_history2.png";
import xxh3 from "../../assets/images/xx_history3.png";
import xxh4 from "../../assets/images/xx_history4.png";
import xxh5 from "../../assets/images/xx_history5.png";
import xxh6 from "../../assets/images/xx_history6.png";
import "../../pages/game/game.css";
import { format_time_table_taixiu } from "../../utils/function";
// import NullData from "../common/null_data";

const xx_history = [xxh1, xxh2, xxh3, xxh4, xxh5, xxh6];

const Table_xucxac = ({ data }) => {
  const sum_char = (number) => {
    const digits = number.split(",").map(Number);
    const sum = digits.reduce((acc, digit) => acc + digit, 0);
    return sum;
  };
  return (
    <div className="wrap-table-taixiu">
      <table className="table-taixiu text-[1.3rem] md:text-[1.2rem] sm:text-[1.1rem] relative">
        <thead>
          <tr className="bg-[#f56464]">
            <th className="w-[20%]">Phiên số</th>
            <th className="w-[25%]">Kết quả</th>
            <th className="w-[15%]">Tài/xỉu</th>
            <th className="w-[15%]">Chẵn/lẻ</th>
            <th className="w-[25%]">Thời gian</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-[20%]">{data?.[0].phien}</td>
            <td></td>
            <td className="w-[15%] text-center">
              <span>Đang chờ kết quả</span>
            </td>
            <td></td>
            <td className="w-[25%]">
              {format_time_table_taixiu(data?.[0].created_at)}
            </td>
          </tr>
          {data?.slice(1).map((item, index) => (
            <tr key={index}>
              <td className="w-[20%]">{item.phien}</td>
              <td className="flex justify-center items-center">
                <img src={xx_history[item.ketqua.split(",")[0] - 1]} />
                <img src={xx_history[item.ketqua.split(",")[1] - 1]} />
                <img src={xx_history[item.ketqua.split(",")[2] - 1]} />
              </td>
              <td
                className={
                  sum_char(item.ketqua) > 10
                    ? "text-green-600"
                    : "text-blue-600"
                }
              >
                {sum_char(item.ketqua) > 10 ? "Tài" : "Xỉu"}
              </td>
              <td
                className={
                  sum_char(item.ketqua) % 2 === 0
                    ? "text-green-600"
                    : "text-blue-600"
                }
              >
                {sum_char(item.ketqua) % 2 === 0 ? "Chẵn" : "Lẻ"}
              </td>
              <td className="w-[25%]">
                {format_time_table_taixiu(item.created_at)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table_xucxac;
