import Title from "../../components/common/tittle";
import Custom_btn from "../../components/common/custom_btn";
import { Link } from "react-router-dom";
import Submit_btn from "../../components/common/submit_btn";

function Service() {
  const handleCSKH = () => {
    window.open("https://direct.lc.chat/17370393/");
  };
  return (
    <>
      <Title name="CSKH" />
      <div className="h-[15rem]"></div>
      <Submit_btn
        width="80%"
        onClick={handleCSKH}
        text="Tới bộ phận CSKH"
      ></Submit_btn>
    </>
  );
}

export default Service;
