import Title from "../../components/common/tittle";
import { useGetFinancialQuery } from "../../redux_toolkit/apiUser";
import { useGetUsersInfoQuery } from "../../redux_toolkit/apiUser";
import {
  today,
  today_year_ago,
  formatTime,
  positive_negative_number,
  format_money,
  formatTime2,
  checkTokenLogin,
} from "../../utils/function";
import { user_system_to_VNese } from "../../utils/const";
import Positive_negative_money from "../../components/common/positive_negative_money";
import NullData from "../../components/common/null_data";
import { useEffect, useState } from "react";
import Loading from "../../components/common/loading";

function History() {
  const [page, setPage] = useState(1);
  const [userName, setUserName] = useState("");
  const [totalPage, setTotalPage] = useState("");
  const [loading, setLoading] = useState(true);
  const handleIncrement = () => {
    setPage(page + 1);
  };
  const handleDecrement = () => {
    setPage(page - 1);
  };

  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();
  useEffect(() => {
    if (dataUserInfo) {
      setUserName(dataUserInfo.username);
    }
  }, [isLoadingUserInfo]);

  const {
    data: dataFinancial,
    isLoading: isLoadingFinancial,
    isFetching: isFetchingFinancial,
    refetch: refetchFinancial,
  } = useGetFinancialQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      by: "all",
      username: userName,
      page: page,
    },
    { refetchOnFocus: true }
  );
  useEffect(() => {
    checkTokenLogin();
  }, []);

  useEffect(() => {
    if (dataFinancial) {
      console.log(dataFinancial);
      setTotalPage(Math.ceil(dataFinancial.data.total / 50));
    }
  }, [isLoadingUserInfo]);

  if (!isLoadingFinancial) {
    console.log(dataFinancial);
  }

  useEffect(() => {
    if (!isLoadingFinancial && !isLoadingUserInfo) {
      setLoading(false);
    }
  }, [isLoadingFinancial, isLoadingUserInfo]);
  return (
    <div className="px-8">
      <Loading loading={loading} />
      <Title name="Biến động số dư" />
      {!dataFinancial ? <NullData /> : <></>}
      <div className="relative z-10 mt-24">
        <div className="flex flex-col bg-[pink] p-2 rounded shadow">
          <span className="sm:text-[1.2rem] md:text-[1.4rem] font-bold">
            {`${
              dataFinancial?.data.total === 0
                ? ""
                : `Trang ${page} / ${totalPage}`
            }`}
          </span>
          <span className="italic text-white font-thin text-[1.2rem] sm:text-[1rem]">{`Kết quả : ${
            dataFinancial?.data.total === 0 ? "0 " : 50 * (page - 1) + 1
          }~${
            50 * page > dataFinancial?.data.total
              ? dataFinancial?.data.total
              : 50 * page
          }/Tổng số ${dataFinancial?.data.total}`}</span>

          {page < totalPage ? (
            <button
              className="absolute top-[50%] translate-y-[-50%] right-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-pink-400 text-white p-2 rounded"
              onClick={handleIncrement}
            >
              Trang tiếp
            </button>
          ) : (
            ""
          )}

          {page > 1 ? (
            <button
              onClick={handleDecrement}
              className="absolute top-[50%] translate-y-[-50%] left-2 text-[1.4rem] sm:text-[1.1rem] md:text-[1.2rem] bg-pink-400 text-white p-2 rounded"
            >
              Trang trước
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      {!isLoadingFinancial && (
        <div className="flex relative z-50 w-full text-[1.4rem] md:text-[1.3rem] sm:text-[1.2rem]">
          {dataFinancial.data.data.length === 0 ? (
            <NullData />
          ) : (
            <div className="bg-[aliceblue] rounded shadow  relative w-full">
              <div className="p-4 max-h-[50rem] overflow-y-auto">
                {dataFinancial.data.data.map((item) => {
                  return (
                    <div className="bg-white border border-solid border-slate-400 rounded-2xl p-4 my-3">
                      <div className="font-bold flex justify-between items-center">
                        <span className="text-left">{item.details}</span>
                        <Positive_negative_money
                          money={item.after - item.before}
                        />
                      </div>
                      <div className="text-left">
                        {user_system_to_VNese[item.by]}
                      </div>
                      <div className="flex justify-start items-center">
                        <span className="mr-2">{`Số dư mới: `}</span>
                        <span className="bg-blue-300 text-white font-bold rounded-lg p-2">
                          {format_money(item.after)}
                        </span>
                      </div>
                      <div className="text-left">{`Thời gian: ${formatTime2(
                        item.created_at
                      )} `}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default History;
