import { useEffect, useState } from "react";
import { numberTofixed } from "../../utils/function";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegCircleCheck } from "react-icons/fa6";
import { useGetUsersInfoQuery } from "../../redux_toolkit/apiUser";
import Dialog_confirm_info_bet from "../modal/Dialog_confirm_info_bet";
function Chip(value, name, className) {
  this.value = value;
  this.name = name;
  this.className = className;
}

const ListChip = [
  new Chip(50000, "50k", "taste_chip_10"),
  new Chip(100000, "100k", "taste_chip_25"),
  new Chip(500000, "500k", "taste_chip_50"),
  new Chip(1000000, "1M", "taste_chip_100"),
  new Chip(5000000, "5M", "taste_chip_200"),
  new Chip(10000000, "10M", "taste_chip_500"),
  new Chip(50000000, "50M", "taste_chip_5"),
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  "max-width": "450px",
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "7px",
};

const XocdiaBet = ({
  tile,
  theloai,
  time,
  sendMsg,
  lastMessage,
  setPageUser,
}) => {
  const [chip, setChip] = useState(0);
  const [cuadat, setCuadat] = useState([]);
  const [money, setMoney] = useState();

  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [dialogConFirm, setDialogConfirm] = useState(false);
  const toggleConfirm = () => {
    if (time < 6) {
      setMessage("Đã hết thời gian cược");
      setOpen(true);
      setDialogConfirm(false);
      return;
    }
    setDialogConfirm(false);
    setTimeout(() => {
      sendMsg({
        xocdia: {
          cuoc: {
            theloai,
            cuoc: money,
            cuadat,
          },
        },
      });
    }, 500);
  };

  const { data: dataUserInfo, refetch: refetchUserInfo } =
    useGetUsersInfoQuery();
  const handleSelectCuadat = (cua) => {
    if (cuadat.includes(cua)) {
      setCuadat((prev) => prev.filter((item) => item !== cua));
    } else {
      setCuadat((prev) => [...prev, cua]);
    }
  };
  const handleChange = (e) => {
    let value = e.target.value;
    value = value.replace(/[^0-9]/g, "");
    value = parseInt(value);
    setMoney(value || 0);
  };
  const handleCuoc = () => {
    if (!dataUserInfo || dataUserInfo?.status === "error") {
      setMessage("Bạn chưa đăng nhập");
      setOpen(true);
      return;
    }

    if (!money) {
      setMessage("Bạn chưa nhập tiền");
      setOpen(true);
      return;
    }
    if (cuadat.length === 0) {
      setMessage("Bạn chưa chọn cửa cược");
      setOpen(true);
      return;
    }

    if (time < 6) {
      setMessage("Đã hết thời gian cược");
      setOpen(true);
      return;
    }
    if (cuadat.length * money > dataUserInfo?.money) {
      setMessage("Số dư của bạn không đủ");
      setOpen(true);
      return;
    }
    setDialogConfirm(true);
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const data = JSON.parse(lastMessage.data);
      if (data?.xocdia?.noticeData || data?.notice) {
        refetchUserInfo();
      }
      if (data?.xocdia?.cuoc) {
        if (data?.xocdia?.cuoc?.success) {
          setMessage("");
          refetchUserInfo();
          setPageUser(1);
        } else setMessage(data?.xocdia?.cuoc?.msg);
        setOpen(true);
      }
    }
  }, [lastMessage]);
  // console.log(cuadat);

  return (
    <>
      <div className="px-[25px] xs:px-[15px] grid grid-cols-2 gap-x-[20px] gap-y-[30px] xs:gap-[15px]">
        <button
          className={`taste_unit_item ${cuadat.includes("le") ? "active" : ""}`}
          onClick={() => handleSelectCuadat("le")}
        >
          <div className="taste_unit_img taste_unit_img_DAN"></div>
          <div className="taste_unit_odds">{numberTofixed(tile?.le || 0)}</div>
        </button>
        <button
          className={`taste_unit_item ${
            cuadat.includes("chan") ? "active" : ""
          }`}
          onClick={() => handleSelectCuadat("chan")}
        >
          <div className="taste_unit_img taste_unit_img_SHUANG"></div>
          <div className="taste_unit_odds">{numberTofixed(tile?.le || 0)}</div>
        </button>
        <button
          className={`taste_unit_item ${
            cuadat.includes("3trang1do") ? "active" : ""
          }`}
          onClick={() => handleSelectCuadat("3trang1do")}
        >
          <div className="nums_yxx_qw flex justify-evenly">
            <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
            <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
            <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
            <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
          </div>
          <div className="taste_unit_odds">
            {numberTofixed(tile?.["3trang1do"] || 0)}
          </div>
        </button>
        <button
          className={`taste_unit_item ${
            cuadat.includes("3do1trang") ? "active" : ""
          }`}
          onClick={() => handleSelectCuadat("3do1trang")}
        >
          <div>
            <div className="nums_yxx_qw flex justify-evenly">
              <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
            </div>
          </div>
          <div className="taste_unit_odds">
            {numberTofixed(tile?.["3do1trang"] || 0)}
          </div>
        </button>
        <button
          className={`taste_unit_item ${
            cuadat.includes("4trang") ? "active" : ""
          }`}
          onClick={() => handleSelectCuadat("4trang")}
        >
          <div>
            <div className="nums_yxx_qw flex justify-evenly">
              <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_w die"></div>
            </div>
          </div>
          <div className="taste_unit_odds">
            {numberTofixed(tile?.["4trang"] || 0)}
          </div>
        </button>
        <button
          className={`taste_unit_item ${
            cuadat.includes("4do") ? "active" : ""
          }`}
          onClick={() => handleSelectCuadat("4do")}
        >
          <div>
            <div className="nums_yxx_qw flex justify-evenly">
              <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
              <div className="taste_unit_item_yxx taste_unit_item_r die"></div>
            </div>
          </div>
          <div className="taste_unit_odds">
            {numberTofixed(tile?.["4do"] || 0)}
          </div>
        </button>
      </div>

      <div className="bet_taste_chips mt-20 xs:mt-14 xs:px-3 flex flex-wrap gap-3 justify-center items-center">
        {ListChip.map((item) => (
          <div
            data-v-331b32c3=""
            className={`taste_chips_swiper_item cursor-pointer xs:basis-1/5 ${
              chip === item.value ? "spinner" : ""
            }`}
            onClick={() => {
              setChip(item.value);
              setMoney(item.value);
            }}
            key={item.value}
          >
            <div
              data-v-331b32c3=""
              flex="main:center cross:center"
              className="taste_chip"
            >
              <div
                data-v-331b32c3=""
                className={`taste_chip_base ${item.className}`}
              >
                <div data-v-331b32c3="" className="item_chip_num">
                  <span data-v-331b32c3="">{item.name}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="bet-input-panel bet_panel_taste mx-5 xs:mx-0 ">
        <div
          data-v-331b32c3=""
          flex="main:justify box:justify cross:center"
          className="bet_taste_info"
        >
          <button
            data-v-331b32c3=""
            className="bet_taste_reset"
            onClick={() => setChip(0)}
          >
            Đặt lại
          </button>
          <div data-v-331b32c3="" className="bet_taste_text">
            <div
              data-v-331b32c3=""
              flex="main:center cross:center"
              className="bet_taste_num vi"
            >
              <span data-v-331b32c3="" className="bet_taste_text__common">
                Đã chọn
              </span>
              <span data-v-331b32c3="" className="bet_taste_text__protrude">
                {cuadat?.length}
              </span>
              <span data-v-331b32c3="" className="bet_taste_text__common">
                Lô
              </span>
            </div>
            <div
              data-v-331b32c3=""
              flex="cross:center"
              className="bet_taste_money"
            >
              <input
                data-v-331b32c3=""
                flex-box="8"
                className="bet_taste_money_bet text-[15px]"
                name="money"
                placeholder="Nhập số tiền"
                value={money?.toLocaleString()}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <button
            data-v-331b32c3=""
            className="bet_taste_submit"
            type="submit"
            onClick={handleCuoc}
          >
            Đặt cược
          </button>
        </div>
      </div>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="flex items-center justify-center flex-col gap-5">
            {!message ? (
              <FaRegCircleCheck
                color="#66bb6a"
                className={`icon ${open && "icon-big"}`}
              />
            ) : (
              <AiOutlineCloseCircle
                color="#f27474"
                className={`icon ${open && "icon-big"}`}
              />
            )}

            <h3 className="text-[27px] text-[#595959] font-bold">
              {!message ? "Thành công" : "Thất bại"}
            </h3>
            <p className="text-[16px] text-[#afafaf]">
              {!message ? "Đặt cược thành công" : message}
            </p>
            <button
              className={`${
                message ? "bg-[#f27474]" : "bg-green-400"
              } rounded-[5px] py-[10px] px-[24px] text-white font-bold text-center mt-5`}
              onClick={() => setOpen(false)}
            >
              OK
            </button>
          </div>
        </Box>
      </Modal>
      <Dialog_confirm_info_bet
        dialog={dialogConFirm}
        toggle1={toggleConfirm}
        toggle2={() => setDialogConfirm(!dialogConFirm)}
        cuadatXocdia={cuadat}
        point={money}
      />
    </>
  );
};

export default XocdiaBet;
