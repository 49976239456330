import "./taixiu.css";
import Xucxac_spin from "../../../components/spin/xucxac_spin/xucxac_spin";
import Taixiu_bet from "./components/taixiu_bet";
import Table_taixiu from "../../../components/table_result/table_taixiu";
import { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import {
  useGetHistoryBetQuery,
  useGetUsersInfoQuery,
  useGetResultsHistoryQuery,
} from "../../../redux_toolkit/apiUser";
import { today, today_year_ago } from "../../../utils/function";
import Heading_taixiu from "./components/heading";
import { HistoryBet } from "./components/historybet";
import { useReducer } from "react";
import Dialog__NoticeWin from "../../../components/modal/Dialog_notice_win";
import NullData from "../../../components/common/null_data";
import { useParams } from "react-router-dom";
import Loading from "../../../components/common/loading";
import config from "../../../config";

const reducer = (state, { action, payload }) => {
  switch (action) {
    case "page":
      return { ...state, page: payload };
    default:
      return state;
  }
};

const socketUrl = config.REACT_APP_WEBSOCKET;
// const socketUrl = process.env.REACT_APP_WEBSOCKET;

function Taixiu() {
  const { type } = useParams();
  // console.log(typeof type);
  // console.log(type);
  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(2);
  const [message, setMessage] = useState();
  // const [messageNotice, setMessageNotice] = useState();
  const [time, setTime] = useState(0);
  const [phien, setPhien] = useState("");
  const [modalWin, setModalWin] = useState(false);
  const [dataModalWin, setDataModalWin] = useState({});

  const [state, dispatch] = useReducer(reducer, {
    page: 1,
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  // console.log(message);
  useEffect(() => {
    sendJsonMessage({ auth: { token: localStorage.getItem("auth") } });
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      const a = JSON.parse(lastMessage.data);

      // console.log(a);
      if (a?.taixiu?.noticeData || a?.notice) {
        setMessage(a);
      } else {
        if (a?.taixiu?.info?.theloai == type) {
          setLoading(false);
          setMessage(a.taixiu.info);
          setPhien(a.taixiu.info.phien);
          if (a.taixiu.info.time > 0) {
            setTime(a.taixiu.info.time);
          } else {
            setTime(0);
          }
        }
      }
    }
  }, [lastMessage]);

  // console.log(message);

  const handleClickHistory = (number) => {
    setHistory(number);
  };
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  const {
    data: dataResultsHistory,
    isLoading: isLoadingResultsHistory,
    isFetching: isFetchingResultHitory,
    refetch: refetchResultsHistory,
  } = useGetResultsHistoryQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "sicbo",
      page: 1,
    },
    { refetchOnFocus: true }
  );
  // console.log(dataResultsHistory);
  const {
    data: dataHistoryBet,
    isLoading: isLoadingHistoryBet,
    isFetching: isFetchingHistoryBet,
    refetch: refetchHistoryBet,
  } = useGetHistoryBetQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "sicbo",
      providercode: "all",
      page: state.page,
    },
    { refetchOnFocus: true }
  );
  useEffect(() => {
    if (message?.taixiu?.noticeData) {
      refetchUserInfo();
      const a = Object.values(message?.taixiu?.noticeData)[0];
      const data = Object.values(a)[0];
      setModalWin(true);
      setDataModalWin(data);
    }
  }, [message?.taixiu?.noticeData]);

  useEffect(() => {
    if (message?.notice) {
      refetchUserInfo();
      refetchHistoryBet();
      // console.log(dataHistoryBet?.data?.data);
    }
  }, [message?.notice]);

  useEffect(() => {
    refetchResultsHistory();
    refetchHistoryBet();
  }, [phien]);

  // console.log(dataModalWin);

  return (
    <>
      <Loading loading={loading} />
      <Heading_taixiu message={message} time={time} phien={phien} />
      <Xucxac_spin message={message} time={time} />
      <Taixiu_bet
        sendJsonMessage={sendJsonMessage}
        message={message}
        dataUser={dataUserInfo}
        type={type}
        time={time}
      />
      <div>
        <div className="flex my-6 justify-between md:text-[1.4rem] sm:text-[1.3rem]">
          <div
            className={
              history === 0
                ? "history-btn-xx active md:text-[1.4rem] sm:text-[1.3rem]"
                : "history-btn-xx md:text-[1.4rem] sm:text-[1.3rem]"
            }
            onClick={() => handleClickHistory(0)}
          >
            Dự án
          </div>
          <div
            className={
              history === 1
                ? "history-btn-xx active md:text-[1.4rem] sm:text-[1.3rem]"
                : "history-btn-xx md:text-[1.4rem] sm:text-[1.3rem]"
            }
            onClick={() => handleClickHistory(1)}
          >
            Lịch sử của tôi
          </div>
          <div
            className={
              history === 2
                ? "history-btn-xx active md:text-[1.4rem] sm:text-[1.3rem]"
                : "history-btn-xx md:text-[1.4rem] sm:text-[1.3rem]"
            }
            onClick={() => handleClickHistory(2)}
          >
            Ẩn lịch sử
          </div>
        </div>
        {history === 0 && <Table_taixiu data={dataResultsHistory} />}
        {history === 1 && (
          <HistoryBet data={dataHistoryBet} state={state} dispatch={dispatch} />
        )}
        {history === 2 && <NullData name="Đã ẩn lịch sử" />}
      </div>
      <Dialog__NoticeWin
        data={dataModalWin}
        dialog={modalWin}
        toggle={() => setModalWin(!modalWin)}
      />
    </>
  );
}

export default Taixiu;
