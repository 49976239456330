import { useEffect, useState } from "react";
import {
  useGetListAvatarQuery,
  useChangeAvatarMutation,
  useGetUsersInfoQuery,
} from "../../redux_toolkit/apiUser";
import Custom_btn from "../common/custom_btn";
import { DialogChangeAvatar } from "../modal/dialog_change_avatar";
import { toast } from "react-toastify";

function Avatar() {
  const { data: dataListAvatar, isLoading: isLoadingListAvatar } =
    useGetListAvatarQuery();

  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  if (!isLoadingListAvatar) {
  }

  const [changeAvatar, { data: res }] = useChangeAvatarMutation();

  const [dialogAvatar, setDialogAvatar] = useState(false);

  const ChangeAvatar = (avatar) => {
    if (avatar) changeAvatar({ avatar });
    setDialogAvatar(!dialogAvatar);
  };

  useEffect(() => {
    if (res) {
      if (res.status === true) {
        refetchUserInfo();
        toast.success(res.msg);
      } else if (res.status === false) {
        toast.error(res.msg);
      }
    }
  }, [res]);

  return (
    <>
      <Custom_btn
        color="#0066cc"
        text="Sửa"
        bottom="0"
        right="0"
        zIndex={1}
        textColol="white"
        onClick={ChangeAvatar}
      />
      {!isLoadingListAvatar && (
        <DialogChangeAvatar
          data={dataListAvatar?.data ?? ""}
          dialogAvatar={dialogAvatar}
          ChangeAvatar={ChangeAvatar}
        />
      )}
    </>
  );
}

export default Avatar;
