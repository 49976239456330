import Countdown from "../../../components/cowndown/cowndown";
import "./xucxac.css";
import "../game.css";

import Table_xucxac from "../../../components/table_result/table_xucxac";
import Heading_game_1 from "../../../components/heading_game/heading_game_1/heading_game_1";

import Xucxac_spin from "../../../components/spin/xucxac_spin/xucxac_spin";
import Taixiu_bet from "./components/taixiu_bet";
import Table_taixiu from "../../../components/table_result/table_taixiu";
import { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import {
  useGetHistoryBetQuery,
  useGetUsersInfoQuery,
  useGetResultsHistoryQuery,
} from "../../../redux_toolkit/apiUser";
import { today, today_year_ago } from "../../../utils/function";
import Heading_xucxac from "./components/heading";
import { HistoryBet } from "./components/historybet";
import { useReducer } from "react";
import Dialog__NoticeWin from "../../../components/modal/Dialog_notice_win";
import NullData from "../../../components/common/null_data";
import { useParams } from "react-router-dom";
import Loading from "../../../components/common/loading";
import config from "../../../config";

const reducer = (state, { action, payload }) => {
  switch (action) {
    case "page":
      return { ...state, page: payload };
    default:
      return state;
  }
};
// const socketUrl = process.env.REACT_APP_WEBSOCKET;
const socketUrl = config.REACT_APP_WEBSOCKET;

function Xucxac() {
  const { type } = useParams();

  const [loading, setLoading] = useState(true);
  const [history, setHistory] = useState(0);
  const [message, setMessage] = useState();
  // const [messageNotice, setMessageNotice] = useState();
  const [time, setTime] = useState(0);
  const [phien, setPhien] = useState("");
  const [created, setCreated] = useState("");
  const [modalWin, setModalWin] = useState(false);
  const [dataModalWin, setDataModalWin] = useState({});
  const [tile, setTile] = useState({});
  const [state, dispatch] = useReducer(reducer, {
    page: 1,
  });
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(socketUrl);
  // console.log(message);
  useEffect(() => {
    sendJsonMessage({ auth: { token: localStorage.getItem("auth") } });
    sendJsonMessage({ taixiu: { getTiLe: true } });
  }, []);

  useEffect(() => {
    if (lastMessage !== null) {
      const a = JSON.parse(lastMessage.data);

      // console.log(a);
      if (a?.taixiu?.noticeData || a?.notice) {
        setMessage(a);
      } else {
        if (a?.taixiu?.info?.theloai == type) {
          setLoading(false);
          setMessage(a.taixiu.info);
          setPhien(a.taixiu.info.phien);
          setCreated(a.taixiu.info.created_at);
          if (a.taixiu.info.time > 0) {
            setTime(a.taixiu.info.time);
          } else {
            setTime(0);
          }
        }
        if (a?.taixiu?.tile) {
          setTile(a?.taixiu?.tile);
        }
      }
    }
  }, [lastMessage]);

  console.log(tile);

  const handleClickHistory = (number) => {
    setHistory(number);
  };
  const {
    data: dataUserInfo,
    isLoading: isLoadingUserInfo,
    isFetching: isFetchingUserInfo,
    refetch: refetchUserInfo,
  } = useGetUsersInfoQuery();

  const {
    data: dataResultsHistory,
    isLoading: isLoadingResultsHistory,
    isFetching: isFetchingResultHitory,
    refetch: refetchResultsHistory,
  } = useGetResultsHistoryQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "sicbo",
      page: 1,
    },
    { refetchOnFocus: true }
  );
  const {
    data: dataHistoryBet,
    isLoading: isLoadingHistoryBet,
    isFetching: isFetchingHistoryBet,
    refetch: refetchHistoryBet,
  } = useGetHistoryBetQuery(
    {
      startDate: today_year_ago(),
      endDate: today(),
      type: type,
      gameType: "sicbo",
      providercode: "all",
      page: state.page,
    },
    { refetchOnFocus: true }
  );
  useEffect(() => {
    if (message?.taixiu?.noticeData) {
      refetchUserInfo();
      const a = Object.values(message?.taixiu?.noticeData)[0];
      const data = Object.values(a)[0];
      setModalWin(true);
      setTimeout(() => {
        setModalWin(false);
      }, 3000);
      setDataModalWin(data);
    }
  }, [message?.taixiu?.noticeData]);

  useEffect(() => {
    if (message?.notice) {
      refetchUserInfo();
      refetchHistoryBet();
      // console.log(dataHistoryBet?.data?.data);
    }
  }, [message?.notice]);

  useEffect(() => {
    refetchResultsHistory();
    refetchHistoryBet();
  }, [message?.phien]);

  // console.log(dataModalWin);

  return (
    <>
      <Loading
        loading={
          isLoadingHistoryBet ||
          isFetchingUserInfo ||
          isLoadingResultsHistory ||
          loading
        }
      />
      <Heading_xucxac message={message} time={time} phien={phien} />
      <Taixiu_bet
        sendJsonMessage={sendJsonMessage}
        message={message}
        dataUser={dataUserInfo}
        type={type}
        time={time}
        tile={tile}
      />
      <div>
        <div className="flex my-6 flex-row flex-wrap">
          <div
            className={
              history === 0
                ? "history-btn-xx active md:text-[1.5rem] sm:text-[1.4rem]"
                : "history-btn-xx md:text-[1.5rem] sm:text-[1.4rem] font-thin"
            }
            onClick={() => handleClickHistory(0)}
          >
            Dự án
          </div>
          <div
            className={
              history === 1
                ? "history-btn-xx active md:text-[1.5rem] sm:text-[1.4rem]"
                : "history-btn-xx md:text-[1.5rem] sm:text-[1.4rem] font-thin"
            }
            onClick={() => handleClickHistory(1)}
          >
            Lịch sử của tôi
          </div>
          {/* <div
            className={
              history === 2
                ? "history-btn-xx active md:text-[1.4rem] sm:text-[1.3rem]"
                : "history-btn-xx md:text-[1.4rem] sm:text-[1.3rem]"
            }
            onClick={() => handleClickHistory(2)}
          >
            Ẩn lịch sử
          </div> */}
        </div>
        <div className="p-4">
          {history === 0 && (
            <Table_xucxac
              data={dataResultsHistory}
              phien={phien}
              created={created}
            />
          )}
          {history === 1 && (
            <HistoryBet
              data={dataHistoryBet}
              state={state}
              dispatch={dispatch}
            />
          )}
          {history === 2 && <NullData name="Đã ẩn lịch sử" />}
        </div>
      </div>
      <Dialog__NoticeWin
        data={dataModalWin}
        dialog={modalWin}
        toggle={() => setModalWin(!modalWin)}
      />
    </>
  );
}

export default Xucxac;
