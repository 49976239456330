import Countdown2 from "../../cowndown/cowndown2";
import "../heading_game.css";
import "./heading_xosonhanh.css";
import { useEffect, useState } from "react";
import Dialog_xoso_history_bet from "../../modal/DialogXosoHistoryBet";
import Dialog_xoso_result_detail from "../../modal/Dialog_xoso_result_detail";
import { convertPhien } from "../../../utils/function";

function Heading_xosonhanh({
  state,
  dispatch,
  dataResultsHistory,
  dataHistoryBet,
  isLoadingHistoryBet,
  message,
  type,
  time,
  phien,
  create,
}) {
  // console.log(time);
  // const state = state;
  // const dispatch = dispatch;
  // console.log(dataHistoryBet);
  // console.log(message);
  const [results, setResults] = useState([]);
  const [more, setMore] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      const randomArray = Array.from({ length: 5 }, () =>
        Math.floor(Math.random() * 10)
      );
      setResults(randomArray);
    }, 100);

    return () => clearInterval(interval);
  }, []);

  const [modalResultHistory, setModalResultHistory] = useState(false);
  const toggle_result_history = () => setModalResultHistory(false);

  const [modalHistoryBet, setModalHistoryBet] = useState(false);
  const toggle_history_bet = () => setModalHistoryBet(false);

  const [dataModalResult, setDataModalResult] = useState({});
  const [g1_7, setG1_7] = useState([]);
  const [dd, setDd] = useState([]);
  const handleModalResult = (number) => {
    if (dataResultsHistory) {
      const data = dataResultsHistory[number];
      setDataModalResult(dataResultsHistory[number]);
      setG1_7(
        ["g1", "g2", "g3", "g4", "g5", "g6", "g7"].map((item) => data[item])
      );
      setDd(
        ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].map(
          (item) => data.dauduoi[item]
        )
      );
      setModalResultHistory(true);
    }
  };
  const handleDialogHBet = () => {
    dispatch({ action: "page", payload: (state.page = 1) });
    setModalHistoryBet(true);
  };
  // console.log(typeof message?.xoso[type].time);

  return (
    <>
      <div className="flex w-full py-4 bg-slate-50 min-h-[16rem] rounded-3xl  z-10 bg-white heading_xsn">
        <div className="heading-col-50">
          <div className="flex">
            <span className="text-gray-400 text-2xl mr-2">Phiên:</span>
            {phien && (
              <span className="text-2xl mb-3 font-bold text-bluelight">
                {convertPhien(phien)}
              </span>
            )}
          </div>

          {message?.xoso?.[type] && <Countdown2 time={time} />}
          <button className="history-bet-btn" onClick={handleDialogHBet}>
            Lịch sử của bạn
          </button>
        </div>
        <div className="heading-col-50">
          <div className="flex mb-4">
            <span className="text-gray-400 text-2xl mr-2">Kết quả phiên:</span>
            {dataResultsHistory && (
              <span className="text-2xl font-bold text-bluelight">
                {convertPhien(dataResultsHistory[0].phien)}
              </span>
            )}
          </div>

          {time > 5 ? (
            <div className="flex mt-4">
              {message.xoso?.[type]?.lastResult.gdb.split("").map((item) => {
                return <span className="red-ball">{item}</span>;
              })}
            </div>
          ) : (
            <div className="flex mt-4">
              {results.map((item) => {
                return <span className="red-ball">{item}</span>;
              })}
            </div>
          )}
          <div>
            <button
              className="detailed-results-btn"
              onClick={() => handleModalResult(0)}
            >
              Chi tiết kết quả
            </button>
            {/* <button
              className="more-btn"
              onClick={() => setMore((value) => !value)}
            >
              Xem thêm
            </button> */}
          </div>
        </div>
      </div>
      {more && (
        <div className="w-100% flex justify-center overflow-y-auto max-h-[40rem] bg-pink-100 wrap-tb">
          <table className="table-results-history">
            <tr className="table-results-history-head">
              <th>Phiên</th>
              <th>Kết quả</th>
            </tr>
            {dataResultsHistory &&
              dataResultsHistory.slice(0, 30).map((item, index) => {
                return (
                  <>
                    <tr onClick={() => handleModalResult(index + 1)}>
                      <td>{item.phien}</td>
                      <td className="flex px-16 justify-between">
                        {item.gdb.split("").map((char) => {
                          return <span className="red-ball">{char}</span>;
                        })}
                      </td>
                    </tr>
                  </>
                );
              })}
          </table>
        </div>
      )}

      <Dialog_xoso_result_detail
        data={dataModalResult}
        g1_7={g1_7}
        dd={dd}
        dialog={modalResultHistory}
        toggle={toggle_result_history}
      />
      {!isLoadingHistoryBet && (
        <Dialog_xoso_history_bet
          dialog={modalHistoryBet}
          data={dataHistoryBet}
          toggle={toggle_history_bet}
          state={state}
          dispatch={dispatch}
        />
      )}
    </>
  );
}

export default Heading_xosonhanh;
