const config = {
  REACT_APP_DOMAIN:
    window.location.hostname === "localhost"
      ? "http://localhost:8000"
      : `https://api.${window.location.hostname}`,
  REACT_APP_WEBSOCKET:
    window.location.hostname === "localhost"
      ? "ws://localhost:8080/connect"
      : `wss://game.${window.location.hostname}/connect`,
  REACT_APP_AGENCY:
    window.location.hostname === "localhost"
      ? "http://localhost:8000"
      : `https://agency.${window.location.hostname}`,
};

export default config;
