import React from "react";
import { FaBell } from "react-icons/fa";
import { useGetNotificationHomeQuery } from "../../../../redux_toolkit/apiUser";
import { IoIosVolumeHigh } from "react-icons/io";
import { FaRegBell } from "react-icons/fa6";

function SliderNotification() {
  const { data, isLoading } = useGetNotificationHomeQuery();
  return (
    <div className="textAnimate">
      <div className="!w-[7rem] flex justify-center items-center">
        <FaRegBell className="shake" size={22} style={{ color: "red" }} />
      </div>
      <div className="wrapper">
        {!isLoading && <p className="target">{data?.title}</p>}
      </div>
    </div>
  );
}

export default SliderNotification;
