import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkTokenApi } from "../utils/function";
import config from "../config";

const domain = config.REACT_APP_DOMAIN;
// const domain = process.env.REACT_APP_DOMAIN;

export const apiAdmin = createApi({
  reducerPath: "apiAdmin",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/portal" }),

  endpoints: builder => ({
    getProgramAgency: builder.query({
      query: () => ({
        url: `/agency/program`,
        headers: headers(),
        method: "GET",
      }),
      transformResponse: res => {
        checkTokenApi(res);
        return res;
      },
    }),
    registerAgency: builder.mutation({
      query: params => ({
        url: `/agency/list`,
        headers: headers(),
        body: params,
        method: "POST",
      }),
      transformResponse: res => {
        checkTokenApi(res);
        return res;
      },
    }),
  }),
});

export const { useGetProgramAgencyQuery, useRegisterAgencyMutation } = apiAdmin;
