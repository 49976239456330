// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  format_money,
  formatTime,
  limitStringLength,
  convertAndFormatDateTime,
  isNumeric,
  convertPhien,
} from "../../utils/function";
import "./modal.css";
import { xo_so } from "../../utils/const";
import NullData from "../common/null_data";
import { BsClipboardData } from "react-icons/bs";
import xoso_components from "../../route/id_game";

import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useState } from "react";

function Dialog_xoso_history_bet({ data, dialog, toggle, state, dispatch }) {
  const total_page = Math.ceil(data?.total / 50);
  const handleIncrement = () => {
    dispatch({ action: "page", payload: state.page + 1 });
  };
  const handleDecrement = () => {
    dispatch({ action: "page", payload: state.page - 1 });
  };

  const filteredArray = (str) => {
    return xoso_components.filter((item) => item.path.includes(str));
  };
  // console.log(filteredArray("xsmb"));
  return (
    <>
      <Dialog
        open={dialog}
        handler={toggle}
        size="sm"
        className="dialog__history-bet"
      >
        <DialogHeader>
          {!data ? (
            ""
          ) : (
            <div className="flex justify-between items-center w-[100%] sm:text-[1.2rem]">
              <div className="flex flex-col bg-blue-200 p-2 rounded shadow">
                <span>
                  {`Lịch sử tham gia ${
                    data.total === 0
                      ? ""
                      : `Trang ${state.page} / ${total_page}`
                  }`}
                </span>
                <span className="italic font-thin text-[1.2rem] sm:text-[1rem]">{`Kết quả : ${
                  data.total === 0 ? "0 " : 50 * (state.page - 1) + 1
                }~${
                  50 * state.page > data.total ? data.total : 50 * state.page
                }/Tổng số ${data.total}`}</span>
              </div>
              <Button
                // variant="text"
                color="#ccc"
                onClick={toggle}
                className="mr-1 border border-solid"
              >
                <span>Đóng</span>
              </Button>
            </div>
          )}
        </DialogHeader>
        <DialogBody className="text-2xl h-[50rem] overflow-scroll sm:text-[1.1rem] md:text-[1.3rem]">
          <>
            {!data ? (
              <>
                <div className="mt-[12rem]"></div>
                <NullData />
              </>
            ) : (
              data.data.map((item) => {
                return (
                  <div className="p-4 rounded-xl bg-[aliceblue] shadow mb-4">
                    <div className="flex px-4 mb-2">
                      <span className="font-bold mr-4">{`Thể loại: ${
                        xo_so[item.type]
                      }`}</span>
                      {item.thanhtoan === 0 ? (
                        <span className="bg-blue-400 rounded-lg px-2 text-white">
                          Chờ mở thưởng
                        </span>
                      ) : item.win > 0 ? (
                        <span className="bg-green-400 rounded-lg px-2 text-white">
                          Thắng cược
                        </span>
                      ) : (
                        <span className="bg-[red] rounded-lg px-2 text-white">
                          Thất bại
                        </span>
                      )}
                    </div>
                    <div className="flex  px-4 justify-between">
                      <span className="text-blue-400 text-left font-bold">{`Cược: ${format_money(
                        item.cuoc
                      )} `}</span>
                      {item.thanhtoan === 1 && item.win > 0 && (
                        <span className="text-green-400 font-bold">{`Thắng: ${format_money(
                          item.win
                        )} `}</span>
                      )}
                    </div>
                    <div className="flex px-4 justify-between">
                      <span>{`Phiên: ${convertPhien(item.phien)}`}</span>
                      {isNumeric(item.theloai) ? (
                        <div>
                          <span className="mr-2">Loại:</span>
                          <span className="font-bold">
                            {formatTime(item.theloai)}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span className="mr-2">Loại:</span>
                          <span className="font-bold">
                            {filteredArray(item.theloai)[0].menu_name}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="flex px-4">{`Số: ${limitStringLength(
                      item.so,
                      40
                    )} `}</div>
                    <div className="flex px-4">
                      {`Thời gian cược: ${convertAndFormatDateTime(
                        item.created_at
                      )}`}
                    </div>
                    {item.thanhtoan === 1 && (
                      <div className="flex px-4">
                        {`Mở thưởng lúc: ${convertAndFormatDateTime(
                          item.updated_at
                        )}`}
                      </div>
                    )}
                  </div>
                );
              })
            )}
            {data?.length !== 0 && state.page === total_page && (
              <div className="w-[100%] my-[2rem] flex justify-center items-center">
                <BsClipboardData className="w-8 h-8" />
                <span className="italic ">Chưa có thêm dữ liệu!</span>
              </div>
            )}
            {data?.total === 0 && (
              <>
                <div className="pt-[8rem]"></div>
                <NullData name="Chưa có dữ liệu" />
              </>
            )}
          </>
        </DialogBody>
        <DialogFooter>
          {state.page < total_page ? (
            <Button onClick={handleIncrement}>Trang kế tiếp</Button>
          ) : (
            ""
          )}
          {state.page > 1 ? (
            <Button onClick={handleDecrement}>Trang trước đó</Button>
          ) : (
            ""
          )}
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default Dialog_xoso_history_bet;
