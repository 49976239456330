import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { headers } from "../utils/const";
import { checkTokenApi } from "../utils/function";
import config from "../config";

const domain = config.REACT_APP_DOMAIN;
// const domain = process.env.REACT_APP_DOMAIN;

export const vipApi = createApi({
  reducerPath: "vipApi",
  baseQuery: fetchBaseQuery({ baseUrl: domain + "/api/user" }),

  endpoints: builder => ({
    getListVip: builder.query({
      query: () => ({
        url: `/vip/list`,
        headers: headers(),
        method: "GET",
      }),
      transformResponse: res => {
        checkTokenApi(res);
        return res.data;
      },
    }),
  }),
});

export const { useGetListVipQuery } = vipApi;
