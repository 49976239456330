import { useState } from "react";
import Countdown2 from "../../../../components/cowndown/cowndown2";
import Dialog__Howplay_xx from "../../../../components/modal/Dialog_cachchoi_xucxac";
// import Xucxac_spin from "../../../../components/spin/xucxac_spin/xucxac_spin";
import Kenospin from "../../../../components/spin/keno_spin/keno_spin";
import Dialog__HowplayKeno from "../../../../components/modal/Dialog_cachchoi_keno";
import { convertPhien } from "../../../../utils/function";

function Heading_keno({ message, type, time, phien }) {
  // console.log(phien);
  const [dialog, setDialog] = useState(false);
  return (
    <div className="rounded-3xl z-10 bg-white sticky top-0">
      <div className="flex pt-12 pb-6 relative">
        <div className="flex py-4 flex-col items-center justify-center w-[50%]">
          <span className="text-gray-400 text-3xl md:text-[1.7rem] sm:text-[1.6rem] ">
            Phiên số:
          </span>
          <span className="text-4xl md:text-[1.9rem] sm:text-[1.7rem] py-4 font-semibold text-bluelight">
            {convertPhien(phien)}
          </span>
          {/* <button
            onClick={() => setDialog(!dialog)}
            className="border border-solid border-[red] rounded-full text-[red] p-2 text-[1.3rem] md:text-[1.1rem] sm:text-[1rem] mt-4"
          >
            Hướng dẫn cách chơi
          </button> */}
        </div>
        <div className="flex py-4 flex-col items-center justify-center w-[50%]">
          <span>Thời gian còn lại:</span>
          <Countdown2 time={time} />
        </div>
        <div className="absolute h"></div>
      </div>

      <Dialog__HowplayKeno dialog={dialog} toggle={() => setDialog(!dialog)} />
    </div>
  );
}

export default Heading_keno;
