import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

export default function Loading({ loading }) {
  if (!loading) return null;
  return (
    <Box className="h-[100vh] absolute top-0 w-full flex justify-center items-center bg-black bg-opacity-50 z-[99999999] px-[1rem] ml-[-1rem] mr-[1rem]">
      <CircularProgress color="error" size={30} />
    </Box>
  );
}
