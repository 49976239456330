// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import "./modal.css";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import airplay from "react-useanimations/lib/airplay";
import UseAnimations from "react-useanimations";
import { format_money, formatTime, formattheloai } from "../../utils/function";
import "./modal.css";
import dayjs from "dayjs";
import { list_keno5, list_xucxac } from "../../utils/const";

function Dialog__NoticeWin({
  data,
  dialog,
  toggle,
  isXocdia = false,
  isKeno = false,
}) {
  // console.log(data);
  return (
    <Dialog
      open={dialog}
      handler={toggle}
      size="xs"
      className="dialog_noticewin"
    >
      <DialogHeader>
        <div className="flex items-center">
          <UseAnimations animation={airplay} size={30} strokeColor="blue" />
          <span className="text-blue-600 font-bold">
            Trúng đẫm rồi, tất tay chơi tới!
          </span>
        </div>
      </DialogHeader>
      <DialogBody>
        <div className="ml-6">
          {data?.cachChoi && (
            <div className="text-[1.3rem] text-blue-400 ">
              <span className="mr-2">Thể loại:</span>
              <span>{formattheloai(data.cachChoi)}</span>
            </div>
          )}
          {data?.theloai && (
            <div className="text-[1.3rem] text-blue-400 ">
              <span className="mr-2">Loại:</span>
              <span>{formattheloai(data.theloai)}</span>
            </div>
          )}
          {data?.cuadat && (
            <div className="text-[1.3rem] text-blue-400 ">
              <span className="mr-2">Cửa đặt:</span>
              <span>
                {isXocdia
                  ? data?.cuadat
                  : isKeno
                  ? list_keno5[data.cuadat]
                  : list_xucxac[data.cuadat]}
              </span>
            </div>
          )}

          <div className="text-[1.3rem] text-blue-400 ">
            <span className="mr-2">Thời gian đặt:</span>
            <span>{dayjs(data.created_at).format("HH:mm:ss")}</span>
          </div>
          <div className="text-[1.3rem] text-blue-400">
            <span className="mr-2">Phiên:</span>
            <span>{data.phien}</span>
          </div>
          {data.cuoc && (
            <div className="text-[1.3rem] text-blue-400">
              <span className="mr-2">Cược:</span>
              <span>{format_money(data.cuoc)}</span>
            </div>
          )}
          {data.money && (
            <div className="text-[1.3rem] text-blue-400">
              <span className="mr-2">Cược:</span>
              <span>{format_money(data.money)}</span>
            </div>
          )}

          <div
            className="text-[1.6rem] text-green-500 font-bold
          "
          >
            <span className="mr-2">Thắng:</span>
            <span>{format_money(data.win)}</span>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button color="secondary" onClick={toggle}>
          Đóng
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default Dialog__NoticeWin;
