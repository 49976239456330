import { useEffect, useRef, useState } from "react";
import "./cowndown.css";
import { formatNumber3 } from "../../utils/function";

function pad(d) {
  return d < 10 ? "0" + d.toString() : d.toString();
}

function Countdown({ time, text1, text2 }) {
  // console.log(time);
  const [hours, setHours] = useState("00");
  const [minutes, setMinutes] = useState("00");
  const [seconds, setSeconds] = useState("00");
  const [timeout, setTimeout] = useState(false);
  let interval = useRef();
  // console.log(time);
  useEffect(() => {
    interval = setInterval(() => {
      const now = new Date();
      const date = time;
      let distance = date - now.getTime();

      const hours_display = Math.floor(distance / (1000 * 60 * 60));
      const minutes_display = Math.floor(
        (distance % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds_display = Math.floor((distance % (1000 * 60)) / 1000);

      if (distance < 0) {
        setTimeout(true);
        clearInterval();
      } else {
        setHours(hours_display);
        setMinutes(minutes_display);
        setSeconds(seconds_display);
      }
    }, 1000);
  }, []);
  return (
    <>
      {timeout ? (
        <span className="flex text-[2rem] text-white items-center font-semibold">
          {text2}
        </span>
      ) : (
        <>
          <span className="text-white text-[1.8rem]">{text1}</span>
          <div className="flex text-[2rem] text-white items-center font-semibold">
            <span className="cowndown-item-2">{formatNumber3(hours)}</span>
            <span className="cowndown-item-2">:</span>
            <span className="cowndown-item-2">{pad(minutes)}</span>
            <span className="cowndown-item-2">:</span>
            <span className="cowndown-item-2">{pad(seconds)}</span>
          </div>
        </>
      )}
    </>
  );
}

export default Countdown;
