import "../login_register.css";
import register_img from "../../../assets/images/register_img.png";
import { GoCodeOfConduct } from "react-icons/go";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsPersonVcard } from "react-icons/bs";

import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { InputGroupStyle } from "../../../components/input/input";
import { FaRegEye, FaRegEyeSlash, FaUser } from "react-icons/fa";
import { BiKey } from "react-icons/bi";
import { Button } from "@material-tailwind/react";
import {
  validatePhone,
  validateUsernamePassword,
} from "../../../utils/function";
import { useGetSettingQuery } from "../../../redux_toolkit/apiUser";
import config from "../../../config";

const domain = config.REACT_APP_DOMAIN;
// const domain = process.env.REACT_APP_DOMAIN;

function Register() {
  const [username, setUsername] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [fullName, setFullName] = useState("");
  const [invite, setInvite] = useState("");
  const [phone, setPhone] = useState("");
  const [typePassword, setTypePassword] = useState(true);
  const [typeRePassword, setTypeRePassword] = useState(true);

  const onChangeTypePW = () => {
    setTypePassword(!typePassword);
  };

  const onChangeTypeRePW = () => {
    setTypeRePassword(!typeRePassword);
  };

  const handleClick = () => {
    if (!username || !pass1 || !pass2 || !phone) {
      return toast.warn("Điền thiếu thông tin!");
    } else if (pass1 !== pass2) {
      return toast.warn("Xác nhận mật khẩu chưa đúng");
    } else {
      if (
        username.trim().length > 20 ||
        pass1.trim().length > 20 ||
        !validateUsernamePassword(username) ||
        !validateUsernamePassword(pass1)
      )
        return toast.warn(
          "Username và mật khẩu tối đa 20 ký tự và không chứa khoảng trắng!"
        );
      if (!validatePhone(phone))
        return toast.warn("Số điện thoại không hợp lệ!");

      axios
        .post(`${domain}/api/user/register`, {
          username: username,
          password: pass1,
          // fullname: fullName,
          sdt: phone,
          // invite: invite,
        })
        .then(function (response) {
          let status = response.data.status;
          if (status === true) {
            setTimeout(() => {
              window.location.href = "/";
            }, 1500);
            localStorage.setItem("auth", response.data.data);
            return toast.success(response.data.msg);
          }
          return toast.error(response.data.msg);
        })
        .catch(function (error) {
          toast.error("Lỗi server");
        });
    }
  };
  const { data, isLoading } = useGetSettingQuery();

  return (
    <>
      <div className="register-img">
        <img
          src={domain + data?.data?.logo_image}
          className="w-[50%]"
          alt="register image"
        />
      </div>

      <div className="text-3xl text-white font-bold pb-4 mt-4">ĐĂNG KÝ</div>

      <div className="mx-12">
        <InputGroupStyle
          name="username"
          label="Tên đăng nhập*"
          decoration={<FaUser size="2rem" className="text-bluelight" />}
          onChange={(e) => setUsername(e.target.value)}
        />
        <InputGroupStyle
          name="password"
          label="Mật khẩu*"
          type={typePassword ? "password" : "text"}
          decoration={<BiKey size="2rem" className="text-bluelight" />}
          decorationPW={
            typePassword ? (
              <FaRegEyeSlash
                size="2rem"
                color="grey"
                onClick={onChangeTypePW}
              />
            ) : (
              <FaRegEye size="2rem" color="grey" onClick={onChangeTypePW} />
            )
          }
          onChange={(e) => setPass1(e.target.value)}
        />

        <InputGroupStyle
          name="re-password"
          label="Nhập lại mật khẩu*"
          type={typeRePassword ? "password" : "text"}
          decoration={<BiKey size="2rem" className="text-bluelight" />}
          decorationPW={
            typeRePassword ? (
              <FaRegEyeSlash
                size="2rem"
                color="grey"
                onClick={onChangeTypeRePW}
              />
            ) : (
              <FaRegEye size="2rem" color="grey" onClick={onChangeTypeRePW} />
            )
          }
          onChange={(e) => setPass2(e.target.value)}
        />
        {/* 
        <InputGroupStyle
          name="code"
          label="Mã giới thiệu (không bắt buộc)"
          decoration={<GoCodeOfConduct size="2rem" color="red" />}
          onChange={(e) => setInvite(e.target.value)}
        /> */}

        <InputGroupStyle
          name="phone"
          label="Số điện thoại*"
          decoration={
            <BiSolidPhoneCall size="2rem" className="text-bluelight" />
          }
          onChange={(e) => setPhone(e.target.value)}
        />

        {/* <InputGroupStyle
          name="fullname"
          label="Họ và tên*"
          decoration={<BsPersonVcard size="2rem" color="red" />}
          onChange={(e) => setFullName(e.target.value)}
        /> */}

        {/* <span className="text-lg text-white">
          Lưu ý*: Cần nhập họ và tên chính xác để liên kết ngân hàng và rút
          tiền!
        </span> */}

        <Button
          variant="gradient"
          className="rounded-full py-4 text-2xl mt-12 button-style__account"
          onClick={handleClick}
          fullWidth
          ripple={true}
        >
          Đăng ký
        </Button>
      </div>

      <div className="mt-8">
        <span className="mr-2 text-2xl">Đã có tài khoản?</span>
        <Link to="/login" className="text-2xl text-[red]">
          Đăng nhập ngay
        </Link>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </>
  );
}

export default Register;
