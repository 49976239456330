import Countdown from "../../../components/cowndown/cowndown";
import "./Xoso_countdown.css";

function Xoso_countdown({ time }) {
  return (
    <div className="timer-box">
      <span className="absolute w-[2rem] h-[2rem] rounded-[1rem] bg-[#fff7f7] left-[50%] top-[-1rem] translate-x-[-50%]"></span>
      <span className="absolute w-[2rem] h-[2rem] rounded-[1rem] bg-[#fff7f7] left-[50%] bottom-[-1rem] translate-x-[-50%]"></span>
      <div className="timer-cowndown border-r border-dashed	 border-white">
        {/* <span className="text-white text-[1.8rem]">Mở thưởng sau:</span> */}
        <Countdown
          time={time + 900000}
          text2="ĐANG QUAY SỐ"
          text1="Mở thưởng sau:"
        />
      </div>
      <div className="timer-cowndown">
        {/* <span className="text-white text-[1.8rem]">Thời gian cược:</span> */}
        <Countdown
          time={time}
          text2="HẾT THỜI GIAN CƯỢC"
          text1="Thời gian cược:"
        />
      </div>
    </div>
  );
}

export default Xoso_countdown;
