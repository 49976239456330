import React from "react";

const Submit_btn = ({ text = "", onClick, disabled = false, width }) => {
  const bg = {
    background: `linear-gradient(to bottom, #007fff, #0066cc)`,
    boxShadow: "0 0.25rem #0066cc",
    fontWeight: "700",
    letterSpacing: "0.2rem",
    fontSize: "2rem",
    width: width,
    borderRadius: "5rem",
    padding: "1.2rem 2rem",
    color: "white",
    marginTop: "1rem",
  };
  return (
    <button onClick={onClick} disabled={disabled} style={bg}>
      {text}
    </button>
  );
};

export default Submit_btn;
