import { useEffect, useRef, useState } from "react";
import "./cowndown.css";
import { kiemTraThoiGian } from "../../utils/function";

function pad(d) {
  if (d < 0) {
    return "00";
  } else {
    return d < 10 ? "0" + d.toString() : d.toString();
  }
}

function Countdown2({ time }) {
  const minutes = Math.floor(kiemTraThoiGian(time) / 60);
  const seconds = Math.floor(kiemTraThoiGian(time) % 60);
  // console.log(time);

  return (
    <div className="flex text-[2rem] text-bluelight bg-slate-300 px-2 py-2 items-center font-bold w-fit">
      <div className="flex">
        {pad(minutes)
          .split("")
          .map((item, i) => {
            return (
              <span key={i} className="bg-[#efeff4] px-3 py-1 mx-[1px]">
                {item}
              </span>
            );
          })}
      </div>
      <span className="mx-2">:</span>
      <div className="flex">
        {pad(seconds)
          .split("")
          .map((item, i) => {
            return (
              <span key={i} className="bg-[#efeff4] px-3 py-1 mx-[1px]">
                {item}
              </span>
            );
          })}
      </div>
    </div>
  );
}

export default Countdown2;
