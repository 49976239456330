// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import UseAnimations from "react-useanimations";
import checkmark from "react-useanimations/lib/checkmark";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function Dialog__SuccessBet({ dialog, toggle }) {
  return (
    <Dialog open={dialog} size="xs">
      <DialogBody>
        <div className="flex justify-center">
          <div className="w-15 h-15 rounded-full border-solid	border-2 border-green-500">
            <UseAnimations
              animation={checkmark}
              size={50}
              strokeColor="green"
            />
          </div>
        </div>
        <span className="block w-full text-center text-blue-600">
          Thao tác thành công
        </span>
      </DialogBody>
      <DialogFooter>
        <Button color="secondary" onClick={toggle}>
          Đóng
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default Dialog__SuccessBet;
