import "./xoso_menugame.css";
import { useState } from "react";
import Xoso_bet from "./xoso_bet";
import Xoso_countdown from "../countdown_zone/xoso";

function Xoso_menugame({
  children,
  props,
  type,
  dataUserInfo,
  isLoadingUserInfo,
  refetchUsernfo,
  message,
  sendJsonMessage,
  time,
  tilecuoc,
  tiletrathuong,
}) {
  const [game, setGame] = useState("Lô");
  const props_child = props.filter((item) => item.name === game)[0];
  // console.log(props);
  // const [tab1, setTab1] = useState("Lô");
  // const [tab2, setTab2] = useState("Lô 2 số");
  // const handleChangeTab1 = (value) => {
  //   setTab1(value);
  //   setTab2(props.filter((item) => item.name === value)[0].types[0].name);
  // };
  // const handleChangeTab2 = (value) => {
  //   setTab2(value);
  // };
  // const props_child = props
  //   .filter((item) => item.name === tab1)[0]
  //   .types.filter((item) => item.name === tab2)[0];
  return (
    <>
      {children?.days && message && (
        <Xoso_countdown time={message?.xoso?.[type]?.nextPhien?.timeEnd} />
      )}
      <div className="flex justify-between mt-8 mb-4">
        {props.map((item, index) => {
          return (
            <div
              key={index}
              className={`w-[18%] text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem] py-3 bg-[#e8e7e8] m-2 shadow rounded-xl  ${
                item.name === game ? "btn-active" : ""
              }`}
              onClick={() => setGame(item.name)}
            >
              {item.name}
            </div>
          );
        })}
      </div>
      {/* <div className="menu-type">
        {props.map((item) => {
          if (item.name === tab1) {
            return item.types.map((type, index) => {
              return (
                <div
                  key={index}
                  className={type.name === tab2 ? "btn-active" : ""}
                  onClick={() => handleChangeTab2(type.name)}
                >
                  {type.name}
                </div>
              );
            });
          }
        })}
      </div> */}
      <Xoso_bet
        props_child={props_child}
        type={type}
        game={game}
        // tab1={tab1}
        // tab2={tab2}
        dataUserInfo={dataUserInfo}
        isLoadingUserInfo={isLoadingUserInfo}
        refetchUsernfo={refetchUsernfo}
        message={message}
        sendJsonMessage={sendJsonMessage}
        time={time}
        tilecuoc={tilecuoc}
        tiletrathuong={tiletrathuong}
      />
    </>
  );
}

export default Xoso_menugame;
