import { useState, useEffect } from "react";
import "./taixiu_bet.css";
import { IoIosWarning } from "react-icons/io";
import Dialog__TimeOut from "../../../../../components/modal/Dialog_time_out";
import Dialog__NotEnoughMoney from "../../../../../components/modal/Dialog_not_enough_money";
import Dialog_confirm_info_bet from "../../../../../components/modal/Dialog_confirm_info_bet";
import Dialog__SuccessBet from "../../../../../components/modal/Dialog_success_bet";
import Dialog__NotLogin from "../../../../../components/modal/Dialog_Not_login";
import {
  format_money_no_monetary_unit,
  format_money,
} from "../../../../../utils/function";
import Input from "../../../../../components/common/input";
import isEmpty from "validator/lib/isEmpty";
import { toast } from "react-toastify";

import {
  cltx,
  so_don,
  tong,
  so_trung_2,
  so_trung_3,
  list_xucxac,
} from "../../../../../utils/const";

const betAmount = ["10000", "100000", "1000000"];

function menu(name, list) {
  this.name = name;
  this.list = list;
}

const list_menu = [
  new menu("CLTX", cltx),
  // new menu("Tổng", tong),
  // new menu("Số đơn", so_don),
  new menu("2 số trùng", so_trung_2),
  new menu("3 số trùng", so_trung_3),
];

function Taixiu_bet({ message, sendJsonMessage, dataUser, type, time, tile }) {
  const [optionSelected, setOptionSelected] = useState([]);
  const [menu, setMenu] = useState("CLTX");
  // const [amountSuggest, setAmountSuggest] = useState("");
  const [pointBet, setPointBet] = useState("");
  const [dialogTimeout, setDialogTimeout] = useState(false);
  const [dialogNotEnoughMoney, setDialogNotEnoughMoney] = useState(false);
  const [dialogConfirmBet, setDialogConfirmBet] = useState(false);
  const [dialogSuccessBet, setDialogSuccessBet] = useState(false);
  const [dialogNotLogin, setDialogNotLogin] = useState(false);
  const handleClickAmount = (number) => {
    // setAmountSuggest(number);
    setPointBet(number);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMulti = (number) => {
    const x = parseInt(pointBet);
    if (x > 0) {
      setPointBet((x * number).toString());
    }
  };

  const handleChange = (event) => {
    setPointBet(event.target.value);
  };

  const handleMenu = (str) => {
    setMenu(str);
    if (menu !== str) {
      setOptionSelected([]);
      setPointBet("");
    }
  };

  const handleOption = (option) => {
    if (optionSelected.includes(option)) {
      setOptionSelected(optionSelected.filter((item) => item !== option));
    } else {
      setOptionSelected([...optionSelected, option]);
    }
  };

  const toggle_SuccessBet = () => {
    setDialogSuccessBet(!dialogSuccessBet);
    setPointBet("");
    setOptionSelected([]);
  };

  const handleConfirm = () => {
    if (time < 6) {
      setDialogTimeout(!dialogTimeout);
    } else if (time > 5) {
      optionSelected.map((item) => {
        sendJsonMessage({
          taixiu: {
            cuoc: {
              theloai: type,
              cuadat: item,
              sotien: pointBet,
            },
          },
        });
      });
    } else {
      toast.warn("Thao tác quá nhanh!!!");
    }
    setDialogConfirmBet(!dialogConfirmBet);
  };

  const handleClickBet = () => {
    if (!dataUser || dataUser?.status === "error") {
      setDialogNotLogin(true);
      return;
    } else if (isEmpty(pointBet)) {
      toast.warn("Chưa nhập số tiền cược");
      return;
    } else if (optionSelected.length === 0) {
      toast.warn("Chưa chọn cửa đặt");
      return;
    } else if (pointBet < 1000) {
      toast.warn("Tiền cược tối thiểu là 1.000");
      return;
    } else if (pointBet * optionSelected.length > dataUser.money) {
      setDialogNotEnoughMoney(!dialogNotEnoughMoney);
      return;
    } else if (time < 6) {
      setDialogTimeout(!dialogTimeout);
      return;
    } else {
      // setDialogConfirmBet(!dialogConfirmBet);
      optionSelected.map((item) => {
        sendJsonMessage({
          taixiu: {
            cuoc: {
              theloai: type,
              cuadat: item,
              sotien: pointBet,
            },
          },
        });
      });
      return;
    }
  };

  useEffect(() => {
    if (message?.notice) {
      if (message.notice.msg === "Đặt cược thành công!") {
        setDialogSuccessBet(true);
        setTimeout(() => {
          setOptionSelected([]);
          setPointBet("");
          setDialogSuccessBet(false);
        }, 3000);
      } else if (message.notice.msg === "Đăng nhập thành công") {
        return;
      } else if (message.notice.msg === "Bạn đã bị khoá cược!") {
        toast.error("Bạn đã bị khóa cược");
        return;
      } else if (message.notice.msg === "Tài khoản đã bị khóa!") {
        localStorage.removeItem("auth");
        localStorage.removeItem("username");
        toast.error("Tài khoản đã bị khóa");
        setTimeout(() => {
          window.location.assign("/login");
        }, 2000);
      }
    }
  }, [message?.notice?.msg]);

  return (
    <div className="bg-white rounded-2xl shadow p-4 my-6">
      <div className="flex flex-col">
        <div className="flex justify-between bg-[#ddd] rounded-lg mb-4">
          {list_menu.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  menu === item.name
                    ? "w-[30%] text-[1.5rem] md:text-[1.3rem] sm:text-[1.1rem] bg-bluelight text-white  p-4 rounded-lg"
                    : "w-[30%] text-[1.5rem] md:text-[1.3rem] sm:text-[1.1rem] bg-[#ddd] text-[#7d7d7d]  p-4 rounded-lg"
                }
                onClick={() => handleMenu(item.name)}
              >
                {item.name}
              </div>
            );
          })}
        </div>

        {list_menu.map((item, index) => {
          return (
            <>
              {menu === item.name && (
                <div
                  key={index}
                  className="flex flex-wrap flex-row items-center justify-center "
                >
                  {item.list.map((option, i) => {
                    return (
                      <div
                        key={i}
                        onClick={() => handleOption(option.code)}
                        className={`flex flex-col items-center justify-center rounded-2xl pointer flex-1 border border-solid h-[7.5rem] md:h-[6rem] sm:h-[5.5rem]  m-2 ${
                          optionSelected.includes(option.code)
                            ? "bg-bluelight "
                            : "border-[#e5e5e5]  bg-white"
                        } ${
                          option.name === "Trùng bất kỳ"
                            ? "min-w-[90%]"
                            : "min-w-[13%]"
                        }`}
                      >
                        <span
                          className={
                            optionSelected.includes(option.code)
                              ? "text-white font-bold"
                              : "text-bluelight font-bold"
                          }
                        >
                          {option.name}
                        </span>
                        <span
                          className={
                            optionSelected.includes(option.code)
                              ? "text-white font-bold text-[1.4rem]"
                              : "text-[1.4rem]"
                          }
                        >
                          {tile[option.code]}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </>
          );
        })}
      </div>
      {optionSelected.length > 0 && (
        <div className="xucxac-bet">
          <div className="w-full text-left my-4 flex items-center">
            <span className="font-bold text-4xl mr-2 md:text-[2rem] sm:text-[1.8rem]">
              Nhập số tiền:
            </span>
            <input
              placeholder="Nhập số tiền cược"
              value={pointBet}
              onChange={handleChange}
              type="number"
            />
          </div>
          {/* <div className="flex">
            {betAmount.map((item, index) => {
              return (
                <span
                  key={index}
                  className="bg-pink-300 p-2 rounded-lg shadow mr-2 text-white text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]"
                  onClick={() => handleClickAmount(item)}
                >
                  {format_money(item)}
                </span>
              );
            })}
            <span
              className="bg-pink-300 p-2 rounded-lg shadow mr-2 text-white text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]"
              onClick={() => handleMulti(2)}
            >
              x2
            </span>
            <span
              className="bg-pink-300 p-2 rounded-lg shadow mr-2 text-white text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]"
              onClick={() => handleMulti(5)}
            >
              x5
            </span>
            <span
              className="bg-pink-300 p-2 rounded-lg shadow mr-2 text-white text-[1.4rem] md:text-[1.2rem] sm:text-[1.1rem]"
              onClick={() => setPointBet("")}
            >
              Reset
            </span>
          </div> */}
          <div className="flex justify-between w-full items-center">
            <div className="flex items-start">
              <div className="mr-4">
                <span className="text-[1.4rem] md:text-[1.3rem] sm:text-[1.2rem] mr-2">
                  Đã chọn:
                </span>
                <span className="text-bluelight text-[1.4rem] md:text-[1.3rem] sm:text-[1.2rem] font-bold">
                  {optionSelected.length}
                </span>
              </div>
              <div>
                <span className="text-[1.4rem] md:text-[1.3rem] sm:text-[1.2rem] mr-2">
                  Tiền cược:
                </span>
                <span className="text-bluelight text-[1.4rem] md:text-[1.3rem] sm:text-[1.2rem]">
                  {format_money(pointBet * optionSelected.length)}
                </span>
              </div>
            </div>
            <button
              className="xx-confirm-button h-fit"
              onClick={handleClickBet}
            >
              Hoàn thành
            </button>
          </div>
        </div>
      )}

      <Dialog__NotEnoughMoney
        dialog={dialogNotEnoughMoney}
        toggle={() => setDialogNotEnoughMoney(!dialogNotEnoughMoney)}
      />
      <Dialog__TimeOut
        dialog={dialogTimeout}
        toggle={() => setDialogTimeout(!dialogTimeout)}
      />
      <Dialog_confirm_info_bet
        dialog={dialogConfirmBet}
        optionSelected={optionSelected}
        point={pointBet}
        cuadat={optionSelected}
        toggle1={handleConfirm}
        toggle2={() => setDialogConfirmBet(!dialogConfirmBet)}
      />
      <Dialog__SuccessBet
        dialog={dialogSuccessBet}
        toggle={toggle_SuccessBet}
      />
      <Dialog__NotLogin
        dialog={dialogNotLogin}
        toggle={() => setDialogNotLogin(!dialogNotLogin)}
      />
    </div>
  );
}

export default Taixiu_bet;
