// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import UseAnimations from "react-useanimations";
import alertTriangle from "react-useanimations/lib/alertTriangle";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

function Dialog__SelectEnoughNumber({ dialog, number, toggle }) {
  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  return (
    <Dialog open={dialog} toggle={toggle}>
      <DialogBody>
        <div className="flex justify-center">
          <div className="w-15 h-15 rounded-full border-solid	border-2 border-red-600">
            <UseAnimations
              animation={alertTriangle}
              size={50}
              strokeColor="red"
            />
          </div>
        </div>
        <span className="block w-full text-center text-blue-600">
          Chọn đủ {number} số
        </span>
      </DialogBody>
      <DialogFooter>
        <Button color="secondary" onClick={toggle}>
          Đóng
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export default Dialog__SelectEnoughNumber;
